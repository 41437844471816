import React, { useState } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button,
  Paper,
  createTheme,
  ThemeProvider,
  CssBaseline,
  CircularProgress
} from '@mui/material';
import { Check, X } from 'lucide-react';
import axios from "axios";
import { API_URL } from "../globals";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: '#121212',
      paper: '#121212',
    },
    primary: {
      main: "#424242",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#121212',
        },
      },
    },
  },
});

const CommitChanges = () => {
  const navigate = useNavigate();
  const { review_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!review_id) {
    return <Navigate to="/notfound" />;
  }

  const handleConfirm = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await axios.post(
        `${API_URL}/apply_suggested_fix`,
        { review_id }
      );
      
      window.history.back();
    } catch (error) {
      console.error('Error applying suggested fix:', error);
      setError(error.response?.data?.message || 'Failed to apply changes. Please try again.');
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            backgroundColor: '#121212',
            border: '1px solid #333',
            borderRadius: 2,
            p: 4,
            maxWidth: '500px',
            width: '90%',
          }}
        >
          <Box sx={{ 
            textAlign: 'center', 
            mb: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3
          }}>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: '#888',
                fontWeight: 500,
                textTransform: 'uppercase',
                letterSpacing: '0.1em',
                fontSize: '0.875rem'
              }}
            >
              Confirm Commit
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              sx={{
                color: '#fff',
                fontWeight: 400,
                lineHeight: 1.3,
                fontSize: '1rem'
              }}
            >
              Are you sure you want to commit the changes submitted by Fume?
            </Typography>
            {isLoading && (
              <Typography
                variant="body2"
                sx={{
                  color: '#888',
                  fontStyle: 'italic'
                }}
              >
                This may take a few seconds...
              </Typography>
            )}
            {error && (
              <Typography
                variant="body2"
                sx={{
                  color: '#ef4444',
                  fontWeight: 500
                }}
              >
                {error}
              </Typography>
            )}
          </Box>

          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            justifyContent: 'center',
          }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                backgroundColor: '#121212',
                border: '1px solid #333',
                borderRadius: 1,
                color: '#888',
                px: 4,
                py: 1.5,
                textTransform: 'none',
                minWidth: '120px',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: 'rgba(239, 68, 68, 0.1)',
                  borderColor: '#ef4444',
                  color: '#ef4444',
                },
              }}
            >
              <X size={16} />
              No
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirm}
              disabled={isLoading}
              sx={{
                backgroundColor: '#252525',
                border: '1px solid #444',
                color: '#888',
                px: 4,
                py: 1.5,
                textTransform: 'none',
                minWidth: '120px',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: 'rgba(34, 197, 94, 0.1)',
                  borderColor: '#22c55e',
                  color: '#22c55e',
                },
              }}
            >
              {isLoading ? (
                <>
                  <CircularProgress size={16} color="inherit" />
                  <Typography>Processing...</Typography>
                </>
              ) : (
                <>
                  <Check size={16} />
                  Yes
                </>
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default CommitChanges; 