import React, { useState, useRef, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CssBaseline,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const tealTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: '#121212',
      paper: '#121212',
    },
    primary: {
      main: "#424242",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#121212',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.12)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.2)',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#424242',
            },
          },
        },
      },
    },
  },
});

const initialQuestions = [
  {
    id: "q1",
    label: "Studio Content",
    description:
      "This is a system prompt describing Fume what's in this studio. You may provide details about the structure and the technologies used in the projects you cloned. It might be helpful to include some of the most important directories and files here.",
  },
  {
    id: "q2",
    label: "Notes to Remember",
    description:
      "These are the most important pieces of information you want Fume to remember. These will not be forgotten no matter how long is the task.",
  },
  {
    id: "q3",
    label: "Fume Ignore",
    description:
      "All of the files and directories that you want Fume to ignore. Everything igonred by your .gitignore file will be automatically ignored by Fume. You do not have list them here.",
  },
  {
    id: "q4",
    label: "App Start",
    type: "unix",
    description: "Provide a series of Unix commands that would fully start your app. This does not need to include the initial setup commands like \`npm install\` or \`yarn install\` as they will be preserved in the studio across tasks. Usually, its a command to kill any proceses in the ports that your app is using, cd into the project directory, and run the start command.",
  },
  {
    id: "q5",
    label: "Preview URL RegEx",
    description:
      "A regular expression pattern to match preview URLs for your application. When given, you do not have to setup the appliation on Fume's studio. Fume will automatically wait for this preview URL to be sent under the pull request as a comment and use it for browser testing. Warning: This feature is only available for GitHub repositories.",
  },
];

const UnixCommandField = ({ value, onChange }) => (
  <TextField
    fullWidth
    multiline
    rows={4}
    value={value}
    onChange={onChange}
    variant="outlined"
    placeholder={`kill -9 $(lsof -t -i:3000 -i:3001 -i:...)\ncd /home/fume/Documents/you-project-name && ...`}
    sx={{
      backgroundColor: '#1e1e1e',
      borderRadius: 1,
      '& .MuiOutlinedInput-root': {
        fontFamily: 'monospace',
        fontSize: '0.875rem',
        lineHeight: '1.5',
      },
      '& .MuiInputBase-input': {
        color: '#fff',
      }
    }}
  />
);

const RegexField = ({ value, onChange }) => {
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const validateRegex = (pattern) => {
    if (!pattern.trim()) {
      setIsValid(true);
      setErrorMessage('');
      return;
    }

    try {
      new RegExp(pattern);
      setIsValid(true);
      setErrorMessage('');
    } catch (e) {
      setIsValid(false);
      setErrorMessage(e.message);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    validateRegex(newValue);
    onChange(e);
  };

  return (
    <Box>
      <TextField
        fullWidth
        value={value}
        onChange={handleChange}
        variant="outlined"
        error={!isValid}
        placeholder="e.g. https?:\/\/[\w-]+(\.[\w-]+)+(:\d+)?.*"
        sx={{
          backgroundColor: '#1e1e1e',
          borderRadius: 1,
          '& .MuiOutlinedInput-root': {
            fontFamily: 'monospace',
            fontSize: '0.875rem',
          },
          '& .MuiInputBase-input': {
            color: '#fff',
          }
        }}
      />
      {!isValid && (
        <Alert 
          severity="error"
          icon={<ErrorOutlineIcon />}
          sx={{ 
            mt: 1,
            backgroundColor: 'rgba(211, 47, 47, 0.1)',
            color: '#ff7070',
            '& .MuiAlert-icon': {
              color: '#ff7070',
            },
          }}
        >
          {errorMessage}
        </Alert>
      )}
      {isValid && value && (
        <Alert 
          severity="success"
          sx={{ 
            mt: 1,
            backgroundColor: 'rgba(46, 125, 50, 0.1)',
            color: '#66bb6a',
          }}
        >
          Valid regular expression
        </Alert>
      )}
    </Box>
  );
};

const parseInitialState = (initialState) => {
  const parts = initialState.split('---\n');
  const answers = {};
  const additionalProcesses = [];

  // Parse the first three questions and Preview URL RegEx
  for (let i = 0; i < 3; i++) {
    answers[`q${i + 1}`] = parts[i] ? parts[i].trim() : '';
  }

  // Parse the processes section (which is part[3] if it exists)
  if (parts.length > 3) {
    const processes = parts[3].split('===\n');
    answers['q4'] = processes[0].trim(); // App Start

    // Add additional processes
    for (let i = 1; i < processes.length; i++) {
      const newId = `q${i + 5}`; // Start from q6 since q5 is Preview URL RegEx
      answers[newId] = processes[i].trim();
      additionalProcesses.push({
        id: newId,
        label: `Process ${i}`,
        type: "unix",
        description: "Provide a series of Unix commands for this process.",
      });
    }
  }

  // Parse Preview URL RegEx (which is part[4] if it exists)
  if (parts.length > 4) {
    answers['q5'] = parts[4].trim();
  }

  return { answers, additionalProcesses };
};

const FormPage = ({ studioId, initialState = '' }) => {
    const { answers: initialAnswers, additionalProcesses } = parseInitialState(initialState);
    const [questions, setQuestions] = useState([...initialQuestions, ...additionalProcesses]);
    const [answers, setAnswers] = useState(initialAnswers);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const formRef = useRef(null);
    const lastQuestionRef = useRef(null);
    const [shouldScroll, setShouldScroll] = useState(false);
  
    const handleChange = (id, value) => {
      setAnswers((prev) => ({ ...prev, [id]: value }));
    };
  
    const handleSubmit = async (e) => {
    e.preventDefault();

    let formattedOutput = "";

    // Add first three questions
    for (let i = 0; i < 3; i++) {
      formattedOutput += `${answers[`q${i + 1}`] || ""}\n---\n`;
    }

    // Add all Unix command processes, starting with App Start
    const processes = questions.filter((q) => q.type === "unix");
    if (processes.length > 0) {
      processes.forEach((process, index) => {
        formattedOutput += `${answers[process.id] || ""}\n`;
        if (index < processes.length - 1) formattedOutput += "===\n";
      });
    }

    // Add Preview URL RegEx
    formattedOutput += `---\n${answers['q5'] || ""}`;

    setIsSaving(true);
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.post(
        `${API_URL}/studio/save-file`,
        {
          studio_id: studioId,
          file_path: "./.fumeconfig",
          content: formattedOutput,
        },
        {
          headers: headers,
        }
      );
      if (response.status !== 200) {
        console.log("Error saving file");
        return;
      }
      setSaveSuccessful(true);
      setTimeout(() => setSaveSuccessful(false), 3000);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  const addProcess = () => {
    const newId = `q${questions.length + 1}`;
    const newQuestion = {
      id: newId,
      label: `Process ${questions.length - 3}`,
      type: "unix",
      description: "Provide a series of Unix commands for this process.",
    };
    setQuestions([...questions, newQuestion]);
    setShouldScroll(true);
  };

  const deleteProcess = (id) => {
    setQuestions(questions.filter((q) => q.id !== id));
    setAnswers((prevAnswers) => {
      const { [id]: deletedAnswer, ...remainingAnswers } = prevAnswers;
      return remainingAnswers;
    });
  };

  useEffect(() => {
    if (shouldScroll && lastQuestionRef.current) {
      lastQuestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setShouldScroll(false);
    }
  }, [questions, shouldScroll]);

  return (
    <ThemeProvider theme={tealTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {saveSuccessful && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
            sx={{
              position: 'fixed',
              top: 16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
              backgroundColor: '#1e4620',
              color: '#fff',
            }}
          >
            File saved successfully
          </Alert>
        )}
        <Container
          maxWidth="md"
          sx={{
            py: 2,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <Typography 
            variant="h5" 
            component="h1" 
            gutterBottom
            sx={{
              color: '#fff',
              fontWeight: 500,
              mb: 2
            }}
          >
            Fume Config
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            ref={formRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
              {questions.map((q, index) => (
                <Box
                  key={q.id}
                  mb={2}
                  ref={index === questions.length - 1 ? lastQuestionRef : null}
                  sx={{
                    borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
                    pb: 2,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography 
                      variant="subtitle1" 
                      gutterBottom
                      sx={{
                        color: '#fff',
                        fontWeight: 500
                      }}
                    >
                      {q.label}
                    </Typography>
                    {q.type === "unix" && index > 3 && (
                      <IconButton
                        onClick={() => deleteProcess(q.id)}
                        color="error"
                        size="small"
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Typography
                    variant="body2"
                    paragraph
                    sx={{ 
                      mb: 1,
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '0.875rem',
                    }}
                  >
                    {q.description}
                  </Typography>
                  {q.type === "unix" ? (
                    <UnixCommandField
                      value={answers[q.id] || ""}
                      onChange={(e) => handleChange(q.id, e.target.value)}
                    />
                  ) : q.label === "Preview URL RegEx" ? (
                    <RegexField
                      value={answers[q.id] || ""}
                      onChange={(e) => handleChange(q.id, e.target.value)}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={answers[q.id] || ""}
                      onChange={(e) => handleChange(q.id, e.target.value)}
                      variant="outlined"
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        borderRadius: 1,
                      }}
                    />
                  )}
                </Box>
              ))}
            </Box>
            <Box 
              sx={{ 
                mb: 2,
                display: 'flex',
                gap: 2,
                borderTop: '1px solid rgba(255, 255, 255, 0.12)',
                pt: 2,
              }}
            >
              <Button
                onClick={addProcess}
                variant="outlined"
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  borderColor: '#555',
                  color: '#fff',
                  '&:hover': {
                    borderColor: '#777',
                    backgroundColor: '#1e1e1e',
                  },
                }}
              >
                Add Process
              </Button>
              <Button 
                type="submit" 
                variant="contained"
                disabled={isSaving}
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  backgroundColor: '#424242',
                  '&:hover': {
                    backgroundColor: '#505050',
                  },
                }}
              >
                {isSaving ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default FormPage;