import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
  IconButton,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Close as CloseIcon, Settings } from "@mui/icons-material";
import { Terminal } from 'lucide-react';
import axios from "axios";
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";

const AddStudioModal = ({ open, onClose, onSuccess }) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [repos, setRepos] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState("");
  const [createFromScratch, setCreateFromScratch] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [selectedZone, setSelectedZone] = useState('');

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const authToken = (await supabase.auth.getSession()).data.session.access_token;
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };
        const response = await axios.get(`${API_URL}/get_github_repo_list`, { headers });
        setRepos(response.data.repos || []);
      } catch (error) {
        console.error("Error fetching repos:", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchRepos();
    }
  }, [open]);

  const handleRepoSelect = async (event) => {
    const selectedRepo = repos.find(repo => repo.id === event.target.value);
    if (!selectedRepo) return;
    
    setSelectedRepo(selectedRepo.id);
    setName(selectedRepo.name);
  };

  const handleCreateFromScratchChange = (event) => {
    setCreateFromScratch(event.target.checked);
    setSelectedRepo("");
    setName("");
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setError("");
  };

  const handleSubmit = async (repoName = name) => {
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      
      let body = { display_name: repoName };
      
      if (selectedZone) {
        body.zone = selectedZone;
      }
      
      if (selectedRepo) {
        const selectedRepoData = repos.find(repo => repo.id === selectedRepo);
        if (selectedRepoData) {
          const [repo_org, repo_name] = selectedRepoData.full_name.split('/');
          body.git_metadata = {
            platform: 'GITHUB',
            repo_org,
            repo_name
          };
        }
      }

      let res = await axios.post(`${API_URL}/studio/create`, body, { headers });
      
      if (res.status !== 200) {
        setError("You have reached the maximum number of studios for your account. Contact contact@fumedev.com to upgrade your account.");
        return;
      }

      const newStudio = {
        id: res.data.id,
        display_name: repoName,
        status: 'BOOTING',
        created_at: new Date().toISOString(),
        tags: []
      };

      onSuccess(newStudio);
      onClose();
      setName("");
      setSelectedRepo("");
    } catch (error) {
      console.error("Error creating studio:", error);
      setError("An error occurred while creating the studio. Please try again.");
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{
          p: 3,
          backgroundColor: '#1a1a1a',
          border: '1px solid #2a2a2a',
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}>
          <CircularProgress size={20} sx={{ color: '#666' }} />
          <Typography sx={{ color: '#888' }}>
            Checking integrations...
          </Typography>
        </Box>
      );
    }

    return (
      <>
        {repos.length > 0 ? (
          <>
            {!createFromScratch ? (
              <FormControl fullWidth>
                <Select
                  value={selectedRepo}
                  onChange={handleRepoSelect}
                  displayEmpty
                  placeholder="Create from a Github Repo"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: '#1a1a1a',
                        backgroundImage: 'none',
                        border: '1px solid #2a2a2a',
                        borderRadius: 1,
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        '& .MuiMenuItem-root': {
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#252525',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#252525',
                          },
                          '&.Mui-disabled': {
                            color: '#666',
                          },
                        },
                      },
                    },
                  }}
                  sx={{
                    height: '48px',
                    '& .MuiSelect-select': {
                      backgroundColor: '#1a1a1a',
                      color: '#fff',
                      padding: '14px 16px',
                      '&.Mui-disabled': {
                        WebkitTextFillColor: '#666',
                      }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2a2a',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#333',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#444',
                    },
                    '& .MuiSelect-icon': {
                      color: '#666',
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography sx={{ color: '#666' }}>
                      Create from a Github Repo
                    </Typography>
                  </MenuItem>
                  {repos.map((repo) => (
                    <MenuItem 
                      key={repo.id} 
                      value={repo.id}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 0.5,
                        py: 1.5,
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#252525',
                        },
                      }}
                    >
                      <Typography sx={{ 
                        fontWeight: 500,
                        color: '#fff'
                      }}>
                        {repo.full_name}
                      </Typography>
                      {repo.description && (
                        <Typography 
                          sx={{ 
                            fontSize: '0.75rem',
                            color: '#888',
                            maxWidth: '400px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {repo.description}
                        </Typography>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                autoFocus
                placeholder="My Development Studio"
                fullWidth
                value={name}
                onChange={handleNameChange}
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#1a1a1a',
                    color: '#fff',
                    '& fieldset': {
                      borderColor: '#2a2a2a',
                    },
                    '&:hover fieldset': {
                      borderColor: '#333',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#444',
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '14px 16px',
                    '&::placeholder': {
                      color: '#666',
                      opacity: 1,
                    },
                  },
                }}
              />
            )}
          </>
        ) : (
          <TextField
            autoFocus
            placeholder="My Development Studio"
            fullWidth
            value={name}
            onChange={handleNameChange}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#1a1a1a',
                color: '#fff',
                '& fieldset': {
                  borderColor: '#2a2a2a',
                },
                '&:hover fieldset': {
                  borderColor: '#333',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#444',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: '14px 16px',
                '&::placeholder': {
                  color: '#666',
                  opacity: 1,
                },
              },
            }}
          />
        )}

        <Box sx={{ mt: 2 }}>
          <Button
            startIcon={<Settings />}
            onClick={() => setShowAdvanced(!showAdvanced)}
            sx={{
              color: '#888',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#252525',
                color: '#fff'
              }
            }}
          >
            Advanced Settings
          </Button>
          
          {showAdvanced && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Select
                value={selectedZone}
                onChange={(e) => setSelectedZone(e.target.value)}
                displayEmpty
                placeholder="Select Machine Region"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: '#1a1a1a',
                      backgroundImage: 'none',
                      border: '1px solid #2a2a2a',
                      borderRadius: 1,
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      '& .MuiMenuItem-root': {
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#252525',
                        },
                      },
                    },
                  },
                }}
                sx={{
                  height: '48px',
                  '& .MuiSelect-select': {
                    backgroundColor: '#1a1a1a',
                    color: '#fff',
                    padding: '14px 16px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2a2a2a',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#333',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#444',
                  },
                  '& .MuiSelect-icon': {
                    color: '#666',
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography sx={{ color: '#666' }}>
                    Select Machine Region
                  </Typography>
                </MenuItem>
                {[
                  'us-west1-a', 'us-west1-b', 'us-west1-c',
                  'us-central1-b', 'us-central1-c', 'us-central1-f',
                  'europe-north1-a', 'europe-west1-b', 'europe-west1-c',
                  'asia-east1-a', 'asia-east1-b', 'asia-east1-c'
                ].map((zone) => (
                  <MenuItem 
                    key={zone} 
                    value={zone}
                    sx={{
                      py: 1.5,
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#252525',
                      },
                    }}
                  >
                    {zone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>

        {repos.length > 0 && (
          <FormControlLabel
            control={
              <Checkbox
                checked={createFromScratch}
                onChange={handleCreateFromScratchChange}
                sx={{
                  color: '#2a2a2a',
                  '&.Mui-checked': {
                    color: '#666',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(102, 102, 102, 0.04)',
                  },
                }}
              />
            }
            label="Create from scratch?"
            sx={{
              mt: 2,
              '& .MuiFormControlLabel-label': {
                color: '#888',
                fontSize: '0.875rem',
              },
            }}
          />
        )}
      </>
    );
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 1,
          border: '1px solid #2a2a2a',
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 3,
        borderBottom: '1px solid #2a2a2a'
      }}>
        <Box>
          <Typography 
            sx={{ 
              color: '#fff',
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 0.5
            }}
          >
            Create New Studio
          </Typography>
          <Typography sx={{ 
            color: '#888',
            fontSize: '0.875rem'
          }}>
            Set up a new development environment
          </Typography>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#252525'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          {renderContent()}

          {error && (
            <Alert 
              severity="error"
              sx={{
                backgroundColor: 'rgba(211, 47, 47, 0.1)',
                color: '#ff6b6b',
                border: '1px solid rgba(211, 47, 47, 0.3)',
                '& .MuiAlert-icon': {
                  color: '#ff6b6b'
                }
              }}
            >
              {error}
            </Alert>
          )}
        </Box>

        {!loading && (
          <Box sx={{ 
            p: 3, 
            borderTop: '1px solid #2a2a2a',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2
          }}>
            <Button
              onClick={onClose}
              sx={{
                color: '#888',
                '&:hover': {
                  backgroundColor: '#252525',
                  color: '#fff'
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              disabled={!name.trim()}
              sx={{
                border: '1px solid #333',
                backgroundColor: 'transparent',
                color: name.trim() ? '#fff' : '#666',
                px: 3,
                '&:hover': {
                  backgroundColor: '#252525',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'transparent',
                  color: '#666',
                  border: '1px solid #2a2a2a',
                }
              }}
            >
              Create Studio
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddStudioModal;
