import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Chip,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import { ExternalLink, LogOut, User, Building2 } from 'lucide-react';
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";
import axios from "axios";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";

const TabItem = ({ icon: Icon, label, isSelected }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1.5,
      width: '100%',
      pl: 2,
    }}
  >
    <Icon size={18} color={isSelected ? '#fff' : '#888'} />
    <Typography
      sx={{
        fontSize: '0.875rem',
        fontWeight: 500,
      }}
    >
      {label}
    </Typography>
  </Box>
);

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  const handleManageSubscription = async () => {
    setIsLoadingSubscription(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      
      const response = await axios.get(`${API_URL}/stripe/billing-portal`, {
        headers: headers,
      });

      if (response.data.url) {
        window.open(response.data.url, '_blank');
      }
    } catch (error) {
      console.error('Error accessing billing portal:', error);
      alert('Error accessing billing portal');
    } finally {
      setIsLoadingSubscription(false);
    }
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('supabase.auth.token');
      localStorage.removeItem('supabase.auth.session');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/signin');
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Error logging out');
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (isLoading) {
    return (
      <>
        <NavBar user={user} />
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#121212'
        }}>
          <CircularProgress size={32} sx={{ color: '#fff' }} />
        </Box>
      </>
    );
  }

  return (
    <>
      <NavBar user={user} />
      <Box sx={{ 
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: '#121212',
      }}>
        {/* Settings Sidebar */}
        <Box sx={{
          width: '240px',
          borderRight: '1px solid #2a2a2a',
          pt: 4,
          px: 2,
          position: 'sticky',
          top: '64px', // Navbar height
          height: 'calc(100vh - 64px)',
          marginLeft: '240px', // Global sidebar width
        }}>
          <Typography
            sx={{
              fontSize: '0.75rem',
              color: '#666',
              fontWeight: 600,
              mb: 2,
              pl: 2,
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
            }}
          >
            Settings
          </Typography>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              minHeight: 'unset',
              '& .MuiTabs-indicator': {
                display: 'none',
              },
              '& .MuiTab-root': {
                minHeight: '40px',
                padding: '8px 0',
                borderRadius: '6px',
                mb: 0.5,
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#ffffff0a',
                },
              },
            }}
          >
            <Tab
              label={<TabItem icon={User} label="Profile" isSelected={activeTab === 0} />}
              sx={{
                color: '#888',
                textTransform: 'none',
                alignItems: 'flex-start',
                width: '100%',
              }}
            />
            <Tab
              label={<TabItem icon={Building2} label="Organization" isSelected={activeTab === 1} />}
              sx={{
                color: '#888',
                textTransform: 'none',
                alignItems: 'flex-start',
                width: '100%',
              }}
            />
          </Tabs>
        </Box>

        {/* Main Content */}
        <Box sx={{
          flexGrow: 1,
          px: 6,
          py: 4,
          minHeight: 'calc(100vh - 64px)',
        }}>
          <Box sx={{
            maxWidth: '1000px',
            margin: '0 auto',
          }}>
            <Box>
              {activeTab === 0 ? (
                <>
                  {/* Profile Tab Content */}
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: 6
                  }}>
                    <Box>
                      <Typography 
                        variant="h4" 
                        sx={{ 
                          fontWeight: 600, 
                          color: '#fff',
                          mb: 1,
                          fontSize: '1.75rem',
                        }}
                      >
                        Profile Settings
                      </Typography>
                      <Typography sx={{ 
                        color: '#888', 
                        fontSize: '0.875rem',
                        maxWidth: '460px',
                        lineHeight: 1.6,
                      }}>
                        Manage your personal information and email preferences.
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{
                    backgroundColor: '#1a1a1a',
                    border: '1px solid #2a2a2a',
                    borderRadius: '8px',
                    p: 4,
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                  }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                      {/* Name Section */}
                      <Box>
                        <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                          NAME
                        </Typography>
                        <Typography sx={{ color: '#fff', fontSize: '0.875rem' }}>
                          {user?.full_name || 'N/A'}
                        </Typography>
                      </Box>

                      {/* Email Section */}
                      <Box>
                        <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                          EMAIL
                        </Typography>
                        <Typography sx={{ color: '#fff', fontSize: '0.875rem' }}>
                          {user?.email || 'N/A'}
                        </Typography>
                      </Box>

                      {/* Logout Section */}
                      <Divider sx={{ borderColor: '#2a2a2a', my: 2 }} />
                      <Box>
                        <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 2 }}>
                          ACCOUNT
                        </Typography>
                        <Button
                          onClick={handleLogout}
                          startIcon={<LogOut size={16} />}
                          sx={{
                            border: '1px solid #333',
                            color: '#ff4444',
                            textTransform: 'none',
                            fontSize: '0.875rem',
                            px: 2,
                            '&:hover': {
                              backgroundColor: '#ff44441a',
                              borderColor: '#ff4444',
                            },
                          }}
                        >
                          Log out
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {/* Organization Tab Content */}
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: 6
                  }}>
                    <Box>
                      <Typography 
                        variant="h4" 
                        sx={{ 
                          fontWeight: 600, 
                          color: '#fff',
                          mb: 1,
                          fontSize: '1.75rem',
                        }}
                      >
                        Organization Settings
                      </Typography>
                      <Typography sx={{ 
                        color: '#888', 
                        fontSize: '0.875rem',
                        maxWidth: '460px',
                        lineHeight: 1.6,
                      }}>
                        Configure your organization preferences, team members, and billing settings.
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{
                    backgroundColor: '#1a1a1a',
                    border: '1px solid #2a2a2a',
                    borderRadius: '8px',
                    p: 4,
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                  }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                      {/* Organization Info */}
                      <Box>
                        <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                          ORGANIZATION NAME
                        </Typography>
                        <Typography sx={{ color: '#fff', fontSize: '0.875rem' }}>
                          {user?.team?.company_name || 'N/A'}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                          ORGANIZATION TIER
                        </Typography>
                        <Typography sx={{ color: '#fff', fontSize: '0.875rem' }}>
                          {user?.team?.tier || 'N/A'}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                          SEAT COUNT
                        </Typography>
                        <Typography sx={{ color: '#fff', fontSize: '0.875rem' }}>
                          {user?.team?.seat_count || 'N/A'}
                        </Typography>
                      </Box>

                      <Divider sx={{ borderColor: '#2a2a2a', my: 2 }} />

                      {/* Subscription Section */}
                      <Box>
                        <Typography sx={{ color: '#888', fontSize: '0.75rem', mb: 1 }}>
                          SUBSCRIPTION
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Chip
                            label={user?.team?.tier || 'N/A'}
                            size="small"
                            sx={{
                              backgroundColor: '#252525',
                              color: '#fff',
                              fontSize: '0.75rem',
                              height: '24px',
                            }}
                          />
                          <Button
                            onClick={handleManageSubscription}
                            startIcon={isLoadingSubscription ? <CircularProgress size={16} sx={{ color: '#fff' }} /> : <ExternalLink size={16} />}
                            disabled={isLoadingSubscription}
                            sx={{
                              border: '1px solid #333',
                              color: '#fff',
                              textTransform: 'none',
                              fontSize: '0.875rem',
                              px: 2,
                              '&:hover': {
                                backgroundColor: '#252525',
                              },
                              '&.Mui-disabled': {
                                color: '#666',
                                borderColor: '#333',
                              }
                            }}
                          >
                            {isLoadingSubscription ? 'Loading...' : 'Manage Seats'}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profile; 