/* global HockeyStack */
import React, { useState, useEffect, useRef, useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, models } from "../globals";
import { supabase } from "../supabaseClient";
import io from "socket.io-client";
import NavBar from "../components/NavBar";
import Loading from "./Loading";
import { useLastChat } from "../contexts/LastChatContext";
import { useAuth } from "../contexts/AuthContext";
import Progress from "../components/Progress";
import FloatingTabSwitch from "../components/FloatingTabSwitch/FloatingTabSwitch";
import Studio from "./Studio/Studio";
import ChatContainer from "../components/ChatContainer";
import ChatHeader from "../components/ChatHeader";
import ChatInput from "../components/ChatInput";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      paper: "#121212",
    },
    primary: {
      main: "#424242",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#121212",
        },
      },
    },
  },
});

export default function Chat() {
  const { task_id } = useParams();
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [liveResponse, setLiveResponse] = useState(null);
  const [progress, setProgress] = useState("");
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const [floatingTabNotification, setFloatingTabNotification] = useState(null);
  const { session } = useAuth();
  const [user, setUser] = useState(null);
  const [task, setTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [planProposed, setPlanProposed] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [activeTab, setActiveTab] = useState("Chat");
  const bottomRef = useRef(null);
  const studioRef = useRef();
  const [isReady, setIsReady] = useState(false);
  const { setLastVisitedChat } = useLastChat();
  const [message, setMessage] = useState("");
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [screenshotUrl, setScreenshotUrl] = useState(null);
  const [currentUrl, setCurrentUrl] = useState(null);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (!task_id) {
      navigate("/notfound");
      return;
    }

    if (session) {
      setAuthToken(session.access_token);
    }
  }, [task_id, session]);

  useEffect(() => {
    if (authToken && task_id) {
      const socket_temp = io(
        `${API_URL.replace("http://", "ws://").replace(
          "https://",
          "wss://"
        )}/chat`,
        {
          query: { task_id: task_id },
          extraHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setSocket(socket_temp);
      getUser();
      getTask();
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [authToken, task_id]);

  useEffect(() => {
    if (socket) {
      socket.on("message", (message) => {
        setStatusMessage("");
        console.log("New Message: ", message);
        if (message.data.type === "chunk") {
          setLiveResponse((prev) => {
            if (prev) {
              return {
                ...prev,
                content: {
                  ...prev.content,
                  data: prev.content.data + message.data.data,
                },
              };
            }
            return {
              id: Date.now(),
              content: {
                type: "liveResponse",
                data: message.data.data,
              },
            };
          });
        } else if (message.data.type === "task_list") {
          try {
            const progressData = JSON.parse(message.data.data);
            setProgress(progressData);
          } catch (error) {
            console.error("Failed to parse progress data:", error);
            setProgress(message.data.data);
          }
        } else if (message.data.type === "set_title") {
          setTask((prev) => ({ ...prev, title: message.data.data.title }));
        } else {
          if (message.data?.data?.screenshot_url) {
            setScreenshotUrl(message.data.data.screenshot_url);
          }

          if (message.data?.data?.url) {
            setCurrentUrl(message.data.data.url);
          }

          setLiveResponse(null);
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: message.data,
            },
          ]);
          if (message.data.wait) {
            setIsLoading(false);
            setStatusMessage("Fume is waiting for your response");
          }
        }

        if (
          [
            "file_edit",
            "create_file",
            "get_definition",
            "look_on_process",
            "run_command",
            "go_to_url",
            "get_file_content",
          ].includes(message.data.type)
        ) {
          if (studioRef.current) {
            studioRef.current.updateStudiData(message.data);
          }
          setFloatingTabNotification("Studio");
          setTimeout(() => {
            setFloatingTabNotification(null);
          }, 20000);
        }
      });

      return () => {
        socket.off("message");
      };
    }
  }, [socket]);

  const sendMessage = (messageData) => {
    if (messageData.attachments.length < 0) {
      setStatusMessage("Fume is thinking");
    } else {
      setStatusMessage("Fume is waking up");
    }
    
    if (socket?.connected) {
      setIsLoading(true);
      setStatusMessage("");
      const messageObj = {
        task_id: task_id,
        message: messageData.text,
        attachments: messageData.attachments,
      };
      setMessages((prev) => [
        ...prev,
        {
          role: "user",
          content: {
            data: messageData.text,
            type: "chat_message",
            attachments: messageData.attachments,
          },
        },
      ]);
      socket.emit("message", JSON.stringify(messageObj));
    }
  };

  const stopTask = async () => {
    const response = await axios.post(
      `${API_URL}/stop`,
      {
        task_id: task_id,
      },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
    if (response.status === 200) {
      setStatusMessage("Fume is stopping");
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  async function getUser() {
    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getTask() {
    try {
      const response = await axios.get(`${API_URL}/chat/${task_id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.data) {
        setTask(response.data);
        console.log(response.data);
        console.log(response.data.stage);
        console.log(response.data.stage === "IN_PROGRESS");
        setIsLoading(response.data.stage === "IN_PROGRESS");
        if (response.data.goa_plan.orchestrator) {
          setProgress(response.data.goa_plan.orchestrator);
        }
        setMessages(response.data.history || []);
        const orchestrator = response.data.goa_plan.orchestrator;
        try {
          setProgress(orchestrator ? JSON.parse(orchestrator) : "");
        } catch (e) {
          setProgress(orchestrator || "");
        }
        setIsReady(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (messages?.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.content.type === "plan") {
        setPlanProposed(true);
      } else {
        setPlanProposed(false);
      }
    }
  }, [messages]);

  let studioFormat = task?.studio?.id && isLargeScreen;

  useEffect(() => {
    // Get the message from URL query parameters when component mounts
    const params = new URLSearchParams(window.location.search);
    const initialMessage = params.get("message");
    if (initialMessage) {
      setMessage(decodeURIComponent(initialMessage));
      // Clean up the URL
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, []);

  const handleTitleSubmit = async (e) => {
    if (e.key === "Enter") {
      setTask((prev) => ({ ...prev, title: editedTitle }));
      setIsEditingTitle(false);
      const response = await axios.put(
        `${API_URL}/task/title`,
        {
          task_id: task_id,
          new_title: editedTitle,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (response.status === 200) {
        return { success: true, submitted: true };
      }
    }
    return { success: false, submitted: false };
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <NavBar user={user} pos={studioFormat ? "left" : "top"} />

      {task?.studio?.id && (
        <ChatHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabWithNotification={floatingTabNotification}
          title={task?.title || "Untitled Chat"}
          isEditingTitle={isEditingTitle}
          setIsEditingTitle={setIsEditingTitle}
          editedTitle={editedTitle}
          setEditedTitle={setEditedTitle}
          onTitleSubmit={handleTitleSubmit}
          showTabs={true}
        />
      )}

      <Container
        component="main"
        maxWidth={false}
        sx={{
          display: "flex",
          height: "100%",
          width: studioFormat ? "calc(100vw - 250px)" : "100vw",
          overflow: "hidden",
          marginLeft: studioFormat ? "240px" : "0px",
          backgroundColor: "#121212",
        }}
      >
        {!isReady && <Loading />}

        {isReady && (
          <>
            <Box
              sx={{
                display: activeTab === "Chat" ? "flex" : "none",
                width: "100%",
                gap: 2,
                pt: "64px", // Add padding top instead of margin
              }}
            >
              <Box
                sx={{
                  width: "70%",
                  height: "100vh",
                  overflow: "hidden",
                  mt: "-64px", // Negative margin to allow scrolling under header
                }}
              >
                <ChatContainer
                  task={task}
                  messages={messages}
                  liveResponse={liveResponse}
                  isLoading={isLoading}
                  planProposed={planProposed}
                  sendMessage={sendMessage}
                  stopTask={stopTask}
                  bottomRef={bottomRef}
                  studioFormat={studioFormat}
                  isLargeScreen={isLargeScreen}
                  message={message}
                  setMessage={setMessage}
                  statusMessage={statusMessage}
                  attachments={attachments}
                  onUpdateAttachments={setAttachments}
                />
              </Box>
              <Box
                sx={{
                  width: "30%",
                  height: "calc(100vh - 64px)",
                  overflow: "hidden",
                  paddingRight: "16px", // Add padding to the right
                  boxSizing: "border-box", // Include padding in width calculation
                }}
              >
                <Progress content={progress} />
              </Box>
            </Box>

            {task?.studio?.id && (
              <Box
                sx={{
                  width: "100%",
                  height: "calc(100vh - 64px)",
                  position: "relative",
                  display: activeTab === "Studio" ? "block" : "none",
                }}
              >
                <Studio
                  studioId={task?.studio?.id}
                  ref={studioRef}
                  screenshotUrl={screenshotUrl}
                  currentUrl={currentUrl}
                />
              </Box>
            )}
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}
