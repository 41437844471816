import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Checkbox,
  CircularProgress,
  InputBase,
  IconButton,
  Modal,
} from '@mui/material';
import { Search as SearchIcon, X } from 'lucide-react';
import { supabase } from "../supabaseClient";
import { API_URL } from "../globals";
import axios from "axios";

const PlaybookSelector = ({ open, onClose, onSelect, selectedPlaybooks = [] }) => {
  const [playbooks, setPlaybooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(selectedPlaybooks);
  }, [selectedPlaybooks]);

  async function fetchWebRecordings() {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    return axios.get(`${API_URL}/studio/ui-recordings`, { headers });
  }

  async function fetchKnowledge() {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    return axios.get(`${API_URL}/studio/knowledge`, { headers });
  }

  async function getPlaybooks() {
    const webRecordings = await fetchWebRecordings();
    const knowledge = await fetchKnowledge();

    try {
      const transformedPlaybooks = webRecordings.data.recordings.map(recording => ({
        id: recording._id,
        name: recording.name || `Untitled Playbook (${recording._id.slice(-6)})`,
        type: 'Web Flow',
        }));

      const transformedKnowledge = knowledge.data.articles.map(article => ({
        id: article.id,
        name: article.title,
        content: article.content,
        type: 'Article',
      }));
      
      setPlaybooks([...transformedPlaybooks, ...transformedKnowledge]);
    } catch (error) {
      console.log(error);
      setPlaybooks([]);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      getPlaybooks();
      setSelected(selectedPlaybooks);
    }
  }, [open, selectedPlaybooks]);

  const filteredPlaybooks = playbooks.filter(playbook =>
    playbook.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleToggle = (playbook) => {
    const currentIndex = selected.findIndex(p => p.id === playbook.id);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(playbook);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleConfirm = () => {
    onSelect(selected);
    onClose();
  };

  const handleClose = () => {
    setSearchQuery('');
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }
      }}
    >
      <Box
        sx={{
          backgroundColor: '#121212',
          border: '1px solid #333',
          borderRadius: 2,
          maxWidth: '500px',
          width: '90%',
          position: 'relative',
          outline: 'none',
        }}
      >
        <Box sx={{ 
          p: 3,
          borderBottom: '1px solid #333',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              color: '#fff',
              fontWeight: 600,
            }}
          >
            Select Playbooks
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              color: '#666',
              '&:hover': {
                color: '#fff',
              },
            }}
          >
            <X size={20} />
          </IconButton>
        </Box>

        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#121212',
              border: '1px solid #333',
              borderRadius: 1,
              px: 2,
              py: 1.5,
              mb: 3,
              '&:hover': {
                borderColor: '#666',
              },
            }}
          >
            <SearchIcon size={18} style={{ color: '#666', marginRight: '8px' }} />
            <InputBase
              placeholder="Search playbooks..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                color: '#fff',
                width: '100%',
                '& input::placeholder': {
                  color: '#666',
                  opacity: 1,
                },
              }}
            />
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress size={24} sx={{ color: '#fff' }} />
            </Box>
          ) : (
            <Box sx={{ 
              maxHeight: '400px', 
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#121212',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#333',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#444',
              },
            }}>
              {filteredPlaybooks.map((playbook) => (
                <Box
                  key={playbook.id}
                  onClick={() => handleToggle(playbook)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#252525',
                    },
                    borderBottom: '1px solid #333',
                    transition: 'background-color 0.2s ease',
                  }}
                >
                  <Checkbox
                    checked={selected.some(p => p.id === playbook.id)}
                    sx={{
                      color: '#666',
                      '&.Mui-checked': {
                        color: '#fff',
                      },
                      '&:hover': {
                        color: '#888',
                      },
                    }}
                  />
                  <Typography sx={{ 
                    color: '#fff', 
                    ml: 1,
                    fontSize: '0.875rem',
                    maxWidth: 'calc(100% - 60px)',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                    {playbook.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid #333',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#121212',
        }}>
          <Typography sx={{ color: '#666' }}>
            {selected.length} selected
          </Typography>
          <Box
            onClick={handleConfirm}
            sx={{
              px: 3,
              py: 1.5,
              backgroundColor: '#121212',
              color: '#fff',
              borderRadius: 1,
              cursor: 'pointer',
              border: '1px solid #333',
              fontSize: '0.875rem',
              '&:hover': {
                backgroundColor: '#252525',
                borderColor: '#666',
              },
              transition: 'all 0.2s ease',
            }}
          >
            Confirm Selection
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PlaybookSelector; 