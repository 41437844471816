import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Close as CloseIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Gitlab } from 'lucide-react';
import axios from 'axios';
import { API_URL } from '../../globals';
import { supabase } from '../../supabaseClient';

const GitlabModal = ({ open, onClose }) => {
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studioOptions, setStudioOptions] = useState([]);
  const [formData, setFormData] = useState({
    token: '',
    studio_id: '',
    project_url: '',
  });

  useEffect(() => {
    if (open) {
      fetchMappings();
      fetchStudios();
    }
  }, [open]);

  const fetchStudios = async () => {
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.get(`${API_URL}/studio/seeds`, { headers });
      
      if (response.data) {
        const studios = response.data.filter(studio => studio.status === "READY" || studio.status === "BUSY");
        const options = studios.map((studio) => ({
          label: studio.display_name,
          value: studio.id,
          type: "studio",
          data: studio,
        }));
        setStudioOptions(options);
      }
    } catch (error) {
      console.error('Failed to fetch studios:', error);
    }
  };

  const fetchMappings = async () => {
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.get(`${API_URL}/repo_mappings`, {
        headers: headers,
        params: {
          platform: 'GITLAB'
        }
      });

      if (response.data?.mappings) {
        console.log(response.data.mappings);
        setMappings(response.data.mappings);
      }
    } catch (error) {
      console.error('Failed to fetch mappings:', error);
    }
  };

  const handleLinkRepository = async () => {
    if (!formData.studio_id || !formData.project_url || !formData.token) {
      return;
    }

    setLoading(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.post(
        `${API_URL}/gitlab_mappings`,
        {
          ...formData,
          project_url: formatGitlabUrl(formData.project_url),
        },
        { headers }
      );

      if (response.status === 200 || response.status === 201) {
        await fetchMappings();
        setFormData({
          token: '',
          studio_id: '',
          project_url: '',
        });
      }
    } catch (error) {
      console.error('Failed to create mapping:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStudioChange = (event) => {
    setFormData(prev => ({
      ...prev,
      studio_id: event.target.value
    }));
  };

  // Add a helper function to get studio display name
  const getStudioDisplayName = (studioId) => {
    const studio = studioOptions.find(option => option.value === studioId);
    return studio?.label || 'Unknown Studio';
  };

  // Add a function to handle URL formatting
  const handleProjectUrlChange = (e) => {
    let url = e.target.value;
    // Remove https:// and .git if they exist to avoid duplication
    url = url.replace(/^https?:\/\//, '').replace(/\.git$/, '');
    setFormData(prev => ({
      ...prev,
      project_url: url
    }));
  };

  // Update the mapping display to show the full URL
  const formatGitlabUrl = (url) => {
    if (!url) return '';
    // Remove existing protocol and .git if present
    const cleanUrl = url.replace(/^https?:\/\//, '').replace(/\.git$/, '');
    return `https://${cleanUrl}`;
  };

  // Add this helper function near the other formatting functions
  const formatGitlabProjectPath = (mapping) => {
    if (!mapping) return '';
    const { groups, project_name } = mapping;
    return groups ? `${groups}/${project_name}` : project_name;
  };

  // Rest of your existing return statement with updated form fields
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 1,
          border: '1px solid #2a2a2a',
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 3,
        borderBottom: '1px solid #2a2a2a'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Gitlab size={24} style={{ color: '#fff' }} />
          <Box>
            <Typography 
              sx={{ 
                color: '#fff',
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 0.5
              }}
            >
              GitLab Integration
            </Typography>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem'
            }}>
              Connect your GitLab repositories
            </Typography>
          </Box>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#252525'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5
            }}>
              To integrate with GitLab, you'll need to provide:
              <Box component="ul" sx={{ mt: 2, pl: 2 }}>
                <li>A GitLab project/group token</li>
                <li>The GitLab project URL</li>
              </Box>
            </Typography>
          </Box>

          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              fontWeight: 600,
              mb: 2
            }}>
              Existing Mappings
            </Typography>
            {mappings.length > 0 ? (
              mappings.map((mapping, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    border: '1px solid #2a2a2a',
                    borderRadius: 1,
                    mb: 1,
                    backgroundColor: '#1a1a1a',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    '&:last-child': {
                      mb: 0
                    }
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                    flex: 1,
                  }}>
                    <Typography sx={{ 
                      color: '#fff',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      backgroundColor: '#252525',
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                    }}>
                      {getStudioDisplayName(mapping.studio_id)}
                    </Typography>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      color: '#666',
                      gap: 1.5 
                    }}>
                      <KeyboardArrowDownIcon sx={{ transform: 'rotate(-90deg)' }} />
                    </Box>
                    <Typography sx={{ 
                      color: '#888',
                      fontSize: '0.875rem',
                    }}>
                      {`https://${mapping.host.replace('https://', '')}/${formatGitlabProjectPath(mapping)}.git`}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography sx={{ 
                color: '#666',
                fontSize: '0.875rem',
                textAlign: 'center',
                py: 2
              }}>
                No mappings found
              </Typography>
            )}
          </Box>

          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5,
              mb: 2
            }}>
              Link your GitLab repository to a studio:
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <FormControl fullWidth>
                <InputLabel 
                  id="studio-select-label"
                  sx={{ 
                    color: '#888',
                    '&.Mui-focused': {
                      color: '#888',
                    },
                  }}
                >
                  Select Studio
                </InputLabel>
                <Select
                  labelId="studio-select-label"
                  value={formData.studio_id}
                  onChange={handleStudioChange}
                  label="Select Studio"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2a2a',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2a2a',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2a2a',
                    },
                    color: '#fff',
                    '& .MuiSelect-icon': {
                      color: '#666',
                    },
                  }}
                >
                  {studioOptions.map((option) => (
                    <MenuItem 
                      key={option.value} 
                      value={option.value}
                      sx={{
                        backgroundColor: '#121212',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#252525',
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#252525',
                        },
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                color: '#666',
                height: '24px'
              }}>
                <KeyboardArrowDownIcon />
              </Box>

              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 2,
                backgroundColor: '#1a1a1a',
                borderRadius: 1,
                p: 2,
                border: '1px solid #2a2a2a'
              }}>
                <Typography sx={{ 
                  color: '#888',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  mb: 1
                }}>
                  GitLab Project Details
                </Typography>
                
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 1,
                  backgroundColor: '#1a1a1a',
                  border: '1px solid #2a2a2a',
                  borderRadius: 1,
                  px: 2,
                  height: '40px',
                }}>
                  <Typography sx={{ 
                    color: '#666',
                    fontSize: '0.875rem',
                  }}>
                    https://
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="gitlab.com/group/project"
                    value={formData.project_url}
                    onChange={handleProjectUrlChange}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'transparent',
                        color: '#fff',
                        fontSize: '0.875rem',
                        '& fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiOutlinedInput-input': {
                        p: '8px 0',
                        height: '24px',
                        '&::placeholder': {
                          color: '#666',
                          opacity: 1,
                        },
                      },
                    }}
                  />
                  <Typography sx={{ 
                    color: '#666',
                    fontSize: '0.875rem',
                  }}>.git</Typography>
                </Box>

                <TextField
                  fullWidth
                  label="Project/Group Token"
                  value={formData.token}
                  onChange={(e) => setFormData({ ...formData, token: e.target.value })}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#1a1a1a',
                      color: '#fff',
                      '& fieldset': {
                        borderColor: '#2a2a2a',
                      },
                      '&:hover fieldset': {
                        borderColor: '#333',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#444',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#888',
                    },
                    '& .MuiOutlinedInput-input': {
                      '&::placeholder': {
                        color: '#666',
                        opacity: 1,
                      },
                    },
                  }}
                />
              </Box>

              <Button
                onClick={handleLinkRepository}
                disabled={loading || !formData.studio_id || !formData.project_url || !formData.token}
                sx={{
                  width: '100%',
                  height: '48px',
                  border: '1px solid #333',
                  backgroundColor: 'transparent',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#252525',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: '#666',
                    border: '1px solid #2a2a2a',
                  }
                }}
              >
                {loading ? 'Linking...' : 'Link Repository'}
              </Button>
            </Box>
          </Box>

        </Box>

        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid #2a2a2a',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#888',
              '&:hover': {
                backgroundColor: '#252525',
                color: '#fff'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onClose}
            sx={{
              border: '1px solid #333',
              backgroundColor: 'transparent',
              color: '#fff',
              px: 3,
              '&:hover': {
                backgroundColor: '#252525',
              },
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: '#666',
                border: '1px solid #2a2a2a',
              }
            }}
          >
            Done
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GitlabModal; 