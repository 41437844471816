import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const GithubCallback = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const isInOnboarding = localStorage.getItem('inOnboarding') === 'true';
    if (isInOnboarding) {
      navigate('/onboarding/github_callback');
    } else {
      navigate('/integrations');
    }
  }, [navigate]);

  // Return null or a loading state while redirecting
  return null;
};

export default GithubCallback;