import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { MessageSquare, ChevronUp } from 'lucide-react';

const PRCommentCard = ({
  filePath,
  comment,
  lineNumber,
  isCollapsed: externalCollapsed,
  onToggleCollapse,
  defaultCollapsed = false,
  animate = true
}) => {
  const [isRevealing, setIsRevealing] = useState(animate);
  const [showContent, setShowContent] = useState(!animate);
  const [showFullPath, setShowFullPath] = useState(!animate);
  const [isCollapsed, setIsCollapsed] = useState(externalCollapsed);
  const fileName = filePath.split('/').pop();

  useEffect(() => {
    if (!animate) {
      setIsRevealing(false);
      setShowFullPath(true);
      setShowContent(true);
      return;
    }

    const revealTimer = setTimeout(() => {
      setIsRevealing(false);
      setTimeout(() => setShowFullPath(true), 300);
      setTimeout(() => setShowContent(true), 300);
    }, 1000);

    return () => clearTimeout(revealTimer);
  }, [animate]);

  useEffect(() => {
    setIsCollapsed(externalCollapsed);
  }, [externalCollapsed]);

  const handleToggleCollapse = () => {
    if (onToggleCollapse) {
      onToggleCollapse(!isCollapsed);
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box sx={{ mb: 3, mx: 2, position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          position: 'relative',
          height: isRevealing ? '200px' : 'auto',
          maxHeight: isRevealing ? '200px' : (isCollapsed ? '60px' : 500),
          p: 2,
          borderRadius: 2,
          color: '#fff',
          maxWidth: '100%',
          backgroundColor: '#0f0f0f',
          border: '1px solid #2a2a2a',
          transition: animate ? 'all 0.3s ease-out' : 'none',
          animation: animate ? 'fadeIn 0.3s ease-in' : 'none',
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(10px)' },
            to: { opacity: 1, transform: 'translateY(0)' }
          },
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: isRevealing ? 0 : 1,
            transform: isRevealing ? 'translateY(10px)' : 'translateY(0)',
            transition: animate ? 'all 0.3s ease-out' : 'none',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MessageSquare
              size={20}
              style={{
                marginRight: '12px',
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                component="span"
                sx={{
                  fontSize: '0.875rem',
                  color: '#666',
                  mr: 1,
                }}
              >
                Added comment to
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component="span"
                  sx={{
                    color: '#666',
                    fontSize: '0.875rem',
                  }}
                >
                  {filePath.substring(0, filePath.lastIndexOf('/'))}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: '#ff9800',
                    fontSize: '0.875rem',
                    ml: 0.5,
                  }}
                >
                  /{fileName}
                </Typography>
              </Box>
            </Box>
          </Box>

          {!isRevealing && (
            <IconButton
              onClick={handleToggleCollapse}
              size="small"
              sx={{
                color: '#fff',
                transition: 'transform 0.2s ease',
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            >
              <ChevronUp size={20} />
            </IconButton>
          )}
        </Box>

        {!isCollapsed && (
          <Box
            sx={{
              mt: 2,
              opacity: showContent ? 1 : 0,
              transition: animate ? 'all 0.3s ease-in' : 'none',
              backgroundColor: 'rgba(255, 255, 255, 0.03)',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: '#666',
                mb: 1,
              }}
            >
              Line {lineNumber}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: '#fff',
                fontFamily: 'monospace',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {comment}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PRCommentCard; 