// File Path: src/components/OnboardingModal.js

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#1e1e1e',
  border: '1px solid rgba(255, 255, 255, 0.12)',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
};

export default function OnboardingModal({ open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="onboarding-modal-title"
      aria-describedby="onboarding-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography 
          id="onboarding-modal-title" 
          variant="h5" 
          component="h2"
          sx={{ 
            mb: 3,
            fontWeight: 500,
            color: '#fff'
          }}
        >
          Welcome to your first studio!
        </Typography>
        <Typography 
          id="onboarding-modal-description" 
          sx={{ 
            color: 'rgba(255, 255, 255, 0.7)',
            textAlign: 'center',
            lineHeight: 1.6
          }}
        >
          Now, you will set up Fume's computer as if you are setting up a computer for a new-hire engineer.
          <br/>
          <br/>
          Please create a git authentication token and use it to clone your repository(s) here <strong><u>in the Documents/ directory</u></strong>.
          <br/>
          <br/>
          Then set up all dependencies and environment variables to run the project. Finally, edit the <strong>.fumeconfig</strong> file to customise your Fume and allow it to run your app.
        </Typography>
        <Button
          onClick={onClose}
          sx={{ 
            mt: 4,
            px: 4,
            py: 1,
            textTransform: 'none',
            backgroundColor: '#2a2a2a',
            borderColor: '#555',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#333',
              borderColor: '#666'
            },
            borderRadius: '6px',
            fontSize: '1rem'
          }}
          variant="outlined"
        >
          Let's Go!
        </Button>
        {/* <Button
          onClick={onClose}
          sx={{ 
            mt: 2,
            color: 'rgba(255, 255, 255, 0.7)',
            textTransform: 'none',
            '&:hover': {
              color: '#fff',
              backgroundColor: 'rgba(255, 255, 255, 0.08)'
            }
          }}
        >
          Close
        </Button> */}
      </Box>
    </Modal>
  );
}
