/* global HockeyStack */
// Chat.js
import React, { useEffect, useState, useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Typography,
  Box,
  CircularProgress
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, ON_PREM } from "../globals";
import { supabase } from "../supabaseClient";
import Loading from "../pages/Loading";
import TaskHistory from "../components/TaskHistory";
import NavBar from "../components/NavBar";
import { useLastChat } from "../contexts/LastChatContext";
import SubscriptionCheckWrapper from "../components/SubscriptionCheckWrapper";
import ChatInput from "../components/ChatInput";
import { useDataSource } from '../contexts/DataSourceContext';

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: '#121212',
      paper: '#121212',
    },
    primary: {
      main: "#424242",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#121212',
        },
      },
    },
  },
});

export default function Home() {

  const navigate = useNavigate();
  
  const [user, setUser] = useState(null);
  const [taskHistory, setTaskHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { lastVisitedChat } = useLastChat();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const bottomRef = useRef(null);

  const [message, setMessage] = useState('');

  const { openDataSourceModal, setLoading } = useDataSource();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (user && user?.email) {
      if (typeof HockeyStack !== 'undefined') HockeyStack.identify(user?.email);
    }
  }, [user]);

  async function checkFreeTrial() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/check_free_trial`, {
        headers: headers,
      });

      const data = response.data;

      if (!data.used_free_trial) {
        const url = await generateStripeCheckoutUrl();
        if (url){
          window.location.href = url;
        }
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function generateStripeCheckoutUrl(){
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/stripe/generate_checkout_url`, {
        headers: headers,
      });
      return response.data.url;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {  
    getUser();
    getTaskHistory();
  }, []);

  async function startTask() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };



    try {
      const response = await axios.post(`${API_URL}/start_task`, null, {
        headers: headers,
      });
      navigate(`/chat/${response.data.task_id}`);
    } catch (error) {
      console.log(error);
    }
  }

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  async function getTaskHistory() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
  
    try {
      const response = await axios.get(`${API_URL}/chats?page=${currentPage}&limit=20`, {
        headers: headers,
      });
  
      if (response.data) {
        setTaskHistory(response.data);
        setTotalPages(Math.ceil(response.data.total / 20));
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingMore(false);
    }
  }

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 5) return {
      text: "Good night",
      emoji: "🌙"
    };
    if (hour < 12) return {
      text: "Good morning", 
      emoji: "☀️"
    };
    if (hour < 17) return {
      text: "Good afternoon",
      emoji: "🌤️"
    };
    if (hour < 20) return {
      text: "Good evening",
      emoji: "🌇"
    };
    return {
      text: "Good night",
      emoji: "🌙"
    };
  };

  const handleMessageSubmit = async (message) => {
    setMessage('');
    const initialMessage = message.text;

    // Open modal immediately with loading state
    setLoading(true);
    openDataSourceModal([], null); // Show modal right away with empty options

    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = { Authorization: `Bearer ${authToken}` };

      const studiosResponse = await axios.get(`${API_URL}/studio/seeds`, { headers });

      if (studiosResponse.data) {
        const studios = studiosResponse.data.filter(studio => studio.status === "READY" || studio.status === "BUSY");
        const studioOptions = studios.map((studio) => ({
          label: studio.display_name,
          value: studio.id,
          type: "studio",
          data: studio,
        }));

        const onSelectCallback = async (selectedOptionValue, selectedOption) => {
          console.log("onSelectCallback");
          try {
            const response = await axios.post(`${API_URL}/start_task`, null, { headers });
            if (response.data?.task_id) {
              if (selectedOption) {
                await axios.put(
                  `${API_URL}/studio/select`,
                  {
                    task_id: response.data.task_id,
                    studio_id: selectedOption.value,
                  },
                  { headers }
                );
              }

              navigate(`/chat/${response.data.task_id}?message=${encodeURIComponent(initialMessage)}`);
            }
          } catch (error) {
            console.error(error);
          }
        };

        // Update the modal with the loaded options
        openDataSourceModal(studioOptions, onSelectCallback, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <SubscriptionCheckWrapper/>
      <NavBar
        user={user}
        taskHistory={taskHistory}
        lastVisitedChat={lastVisitedChat}
      />
      <Box 
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'transparent',
          pt: 4
        }}
      >
        <Box 
          sx={{
            width: '70%',
            margin: '0 auto',
            backgroundColor: 'transparent',
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: '1024px',
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Box sx={{ 
                textAlign: 'center',
                mb: 2
              }}>
                <Typography 
                  variant="h3" 
                  sx={{ 
                    color: '#fff',
                    fontWeight: 600,
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2
                  }}
                >
                  <span style={{ fontSize: '1.2em' }}>{getGreeting().emoji}</span>
                  {getGreeting().text}, {user?.full_name.split(" ")[0]}
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ 
                    color: '#888',
                    fontWeight: 400
                  }}
                >
                  How can I help you ship today?
                </Typography>
              </Box>

              <Box sx={{
                backgroundColor: '#121212',
                p: 2,
                maxWidth: '800px',
                alignSelf: 'center',
                width: '100%'
              }}>
                <ChatInput
                  onSendMessage={handleMessageSubmit}
                  isLoading={false}
                  message={message}
                  setMessage={setMessage}
                  mode="home"
                />
              </Box>

              <Box sx={{
                width: '100%',
                overflowX: 'auto'
              }}>
                <TaskHistory taskHistory={taskHistory} />
              </Box>

              {isLoadingMore && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <CircularProgress />
                </Box>
              )}
              <div ref={bottomRef} />
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
