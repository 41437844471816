import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Slack, Check } from 'lucide-react';
import { API_URL } from '../../globals';
import { useAuth } from '../../contexts/AuthContext';

const SlackModal = ({ open, onClose }) => {
  const { session } = useAuth();
  const [channel, setChannel] = useState('general');
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isLoadingChannel, setIsLoadingChannel] = useState(false);

  useEffect(() => {
    const fetchSlackChannel = async () => {
      if (!session?.access_token) return;

      setIsLoadingChannel(true);
      try {
        const response = await axios.get(
          `${API_URL}/get_slack_comm_channel`,
          {
            headers: { Authorization: `Bearer ${session.access_token}` }
          }
        );

        if (response.data?.slack_comm_channel) {
          setChannel(response.data.slack_comm_channel);
        }
      } catch (error) {
        console.error('Error fetching Slack channel:', error);
      } finally {
        setIsLoadingChannel(false);
      }
    };

    fetchSlackChannel();
  }, [session?.access_token]);

  const handleChannelChange = (event) => {
    // Store value without the #
    setChannel(event.target.value.replace('#', ''));
    setSaveSuccess(false);
  };

  const handleSaveChannel = async () => {
    try {
      setIsSaving(true);
      setSaveSuccess(false);
      const authToken = session?.access_token;

      if (!authToken) {
        throw new Error('No authentication token found');
      }

      const response = await axios.post(
        `${API_URL}/change_slack_comm_channel`,
        { slack_comm_channel: channel },
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );

      if (response.status === 200) {
        setSaveSuccess(true);
      }
    } catch (error) {
      console.error('Error saving Slack channel:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const authToken = session?.access_token;

      if (!authToken) {
        throw new Error('No authentication token found');
      }

      // Make request to slack-install endpoint with auth header
      const response = await axios.get(`${API_URL}/slack-install`, {
        headers: { 
          Authorization: `Bearer ${authToken}` 
        },
        maxRedirects: 5,
        validateStatus: function (status) {
          return status >= 200 && status < 400;
        },
      });

      // If we get a redirect URL, navigate to it
      if (response.data?.url) {
        window.location.href = response.data.url;
      } else {
        window.location.href = response.request.responseURL;
      }
      
      onClose();
    } catch (error) {
      console.error('Error starting Slack installation:', error);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 1,
          border: '1px solid #2a2a2a',
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 3,
        borderBottom: '1px solid #2a2a2a'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Slack size={24} style={{ color: '#fff' }} />
          <Box>
            <Typography 
              sx={{ 
                color: '#fff',
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 0.5
              }}
            >
              Slack Integration
            </Typography>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem'
            }}>
              Add Fume to your Slack workspace
            </Typography>
          </Box>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#252525'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5
            }}>
              You can connect Fume to your Slack workspace so it can ping you whenever it needs your help. Fume will send messages to your pre-designated channel.
              <Box component="ul" sx={{ mt: 2, pl: 2 }}>
                <li>Install the Fume Slack app to your workspace</li>
                <li>Add the name of your Slack channel for Fume to send messages to</li>
                <li>Save the channel name</li>
              </Box>
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
            <Typography sx={{ 
              color: '#fff',
              fontSize: '0.875rem',
              fontWeight: 500,
              mb: 0.5
            }}>
              Preferred Channel
            </Typography>
            <Box sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center'
            }}>
              <TextField
                value={channel}
                onChange={handleChannelChange}
                placeholder="general"
                disabled={isLoadingChannel}
                InputProps={{
                  startAdornment: <Typography sx={{ color: '#666' }}>#</Typography>,
                  endAdornment: isLoadingChannel ? (
                    <CircularProgress size={16} sx={{ color: '#666', mr: 1 }} />
                  ) : null,
                  sx: {
                    color: '#fff',
                    backgroundColor: '#1a1a1a',
                    border: '1px solid #2a2a2a',
                    borderRadius: 1,
                    height: '40px',
                    '&:hover': {
                      borderColor: '#666',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }
                }}
                sx={{
                  flexGrow: 1,
                  '& input': {
                    color: '#fff',
                    pl: 0.5,
                    height: '8px',
                  }
                }}
              />
              <Button
                onClick={handleSaveChannel}
                disabled={isSaving}
                sx={{
                  minWidth: '100px',
                  height: '40px',
                  border: '1px solid #333',
                  backgroundColor: 'transparent',
                  color: saveSuccess ? '#4caf50' : '#fff',
                  px: 3,
                  '&:hover': {
                    backgroundColor: '#252525',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: '#666',
                    border: '1px solid #2a2a2a',
                  }
                }}
              >
                {isSaving ? (
                  <CircularProgress size={20} sx={{ color: '#fff' }} />
                ) : saveSuccess ? (
                  <Check size={20} />
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid #2a2a2a',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#888',
              '&:hover': {
                backgroundColor: '#252525',
                color: '#fff'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{
              border: '1px solid #333',
              backgroundColor: 'transparent',
              color: '#fff',
              px: 3,
              '&:hover': {
                backgroundColor: '#252525',
              },
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: '#666',
                border: '1px solid #2a2a2a',
              }
            }}
          >
            Install Slack App
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SlackModal; 