import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import MDEditor from '@uiw/react-md-editor';

const PlaybookModal = ({ open, onClose, mode = 'create', initialData = null, onSave }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.name || '');
      setContent(initialData.content || '');
    }
  }, [initialData]);

  const handleSave = () => {
    onSave({
      title,
      content,
    });
    handleClose();
  };

  const handleClose = () => {
    setTitle('');
    setContent('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          border: '1px solid #333',
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ 
        color: '#fff',
        borderBottom: '1px solid #333',
        p: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {mode === 'create' ? 'Create New Playbook' : 'Edit Playbook'}
        </Typography>
        <IconButton 
          onClick={handleClose} 
          size="small" 
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <TextField
          fullWidth
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{
            mt: 1,
            mb: 3,
            '& .MuiOutlinedInput-root': {
              color: '#fff',
              backgroundColor: '#121212',
              '& fieldset': {
                borderColor: '#333',
              },
              '&:hover fieldset': {
                borderColor: '#666',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#666',
              },
            },
            '& .MuiInputLabel-root': {
              color: '#666',
            },
          }}
        />

        <Box sx={{ 
          '& .w-md-editor': {
            backgroundColor: '#121212',
            color: '#fff',
            border: '1px solid #333',
            borderRadius: 1,
          },
          '& .w-md-editor-text-pre > code': {
            color: '#fff !important',
          },
          '& .w-md-editor-text-input': {
            color: '#fff !important',
          },
          '& .wmde-markdown-color': {
            backgroundColor: '#121212 !important',
            color: '#fff !important',
          },
          '& .w-md-editor-toolbar': {
            backgroundColor: '#121212 !important',
            borderBottom: '1px solid #333',
          },
          '& .w-md-editor-toolbar li > button': {
            color: '#666 !important',
            '&:hover': {
              color: '#fff !important',
            },
          },
        }}>
          <MDEditor
            value={content}
            onChange={setContent}
            height={400}
            preview="edit"
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ 
        p: 3, 
        borderTop: '1px solid #333',
        gap: 1
      }}>
        <Button 
          onClick={handleClose}
          sx={{
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: 'transparent',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          sx={{
            border: '1px solid #333',
            backgroundColor: '#121212',
            color: '#fff',
            px: 3,
            py: 1.5,
            textTransform: 'none',
            fontSize: '0.875rem',
            '&:hover': {
              backgroundColor: '#252525',
              borderColor: '#666',
            },
          }}
        >
          {mode === 'create' ? 'Create Playbook' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlaybookModal;