import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Github, MoreVertical, Trash2 } from 'lucide-react';
import axios from 'axios';
import { API_URL } from '../../globals';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import Menu from '@mui/material/Menu';

const GithubModal = ({ open, onClose, studios = [] }) => {
  const { session } = useAuth();
  const [mappings, setMappings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studioOptions, setStudioOptions] = useState([]);
  const [formData, setFormData] = useState({
    repo_name: '',
    repo_org: '',
    studio_id: '',
    development_branch: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchMappings();
      fetchStudios();
    }
  }, [open]);

  const fetchStudios = async () => {
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.get(`${API_URL}/studio/seeds`, { headers });
      
      if (response.data) {
        const studios = response.data.filter(studio => studio.status === "READY" || studio.status === "BUSY");
        const options = studios.map((studio) => ({
          label: studio.display_name,
          value: studio.id,
          type: "studio",
          data: studio,
        }));
        setStudioOptions(options);
      }
    } catch (error) {
      console.error('Failed to fetch studios:', error);
    }
  };

  const fetchMappings = async () => {
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.get(`${API_URL}/repo_mappings`, {
        headers: headers,
      });

      if (response.data?.mappings) {
        setMappings(response.data.mappings);
      }
    } catch (error) {
      console.error('Failed to fetch mappings:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;

      if (!authToken) {
        throw new Error('No authentication token found');
      }

      // Make request to slack-install endpoint with auth header
      const response = await axios.get(`${API_URL}/github-install`, {
        headers: { 
          Authorization: `Bearer ${authToken}` 
        },
        maxRedirects: 5,
        validateStatus: function (status) {
          return status >= 200 && status < 400;
        },
      });

      // If we get a redirect URL, navigate to it
      if (response.data?.url) {
        window.location.href = response.data.url;
      } else {
        window.location.href = response.request.responseURL;
      }
      
      onClose();
    } catch (error) {
      console.error('Error starting GitHub installation:', error);
    }
  };

  const handleLinkRepository = async () => {
    if (!formData.studio_id || !formData.repo_name) {
      return;
    }

    setLoading(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.post(
        `${API_URL}/github_mappings`,
        {
          ...formData,
          source: 'GITHUB'
        },
        { headers }
      );

      if (response.status === 201) {
        await fetchMappings();
        setFormData({
          repo_name: '',
          repo_org: '',
          studio_id: '',
          development_branch: '',
        });
      }
    } catch (error) {
      console.error('Failed to create mapping:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStudioChange = (event) => {
    setFormData(prev => ({
      ...prev,
      studio_id: event.target.value
    }));
  };

  // Add a helper function to get studio display name
  const getStudioDisplayName = (studioId) => {
    const studio = studioOptions.find(option => option.value === studioId);
    return studio?.label || 'Unknown Studio';
  };

  const handleMenuOpen = (event, mapping) => {
    setAnchorEl(event.currentTarget);
    setSelectedMapping(mapping);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMapping(null);
  };

  const handleDeleteMapping = async () => {
    if (!selectedMapping) return;
    
    setDeleteLoading(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      await axios.delete(`${API_URL}/github_mappings/${selectedMapping.id}`, { headers });
      await fetchMappings();
    } catch (error) {
      console.error('Failed to delete mapping:', error);
    } finally {
      setDeleteLoading(false);
      handleMenuClose();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 1,
          border: '1px solid #2a2a2a',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '90vh'
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 3,
        borderBottom: '1px solid #2a2a2a'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Github size={24} style={{ color: '#fff' }} />
          <Box>
            <Typography 
              sx={{ 
                color: '#fff',
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 0.5
              }}
            >
              GitHub Integration
            </Typography>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem'
            }}>
              Connect your GitHub organization
            </Typography>
          </Box>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#252525'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ 
        p: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#333',
          borderRadius: '4px',
        }
      }}>
        <Box sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5
            }}>
              You can connect Fume to your Github organization to receive pull requests. To integrate Fume with GitHub, you'll need to install Fume Github App on your organization
              with the following permissions:
              <Box component="ul" sx={{ mt: 2, pl: 2 }}>
                <li>Click on the "Install App" button below</li>
                <li>Select the organization you'd like to connect</li>
                <li>Click "Authorize"</li>
                <li>Link a studio to your repositories</li>
              </Box>
            </Typography>

            <Button
              onClick={handleSubmit}
              disabled={loading}
              sx={{
                mt: 3,
                width: '100%',
                border: '1px solid #333',
                backgroundColor: 'transparent',
                color: '#fff',
                py: 1,
                '&:hover': {
                  backgroundColor: '#252525',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'transparent',
                  color: '#666',
                  border: '1px solid #2a2a2a',
                }
              }}
            >
              {loading ? 'Installing...' : 'Install App'}
            </Button>
          </Box>

          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              fontWeight: 600,
              mb: 2
            }}>
              Existing Mappings
            </Typography>
            {mappings.length > 0 ? (
              mappings.map((mapping, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    border: '1px solid #2a2a2a',
                    borderRadius: 1,
                    mb: 1,
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    '&:last-child': {
                      mb: 0
                    }
                  }}
                >
                  <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography sx={{ 
                      color: '#888',
                      fontSize: '0.875rem',
                      fontWeight: 500
                    }}>
                      {getStudioDisplayName(mapping.studio_id)}
                    </Typography>
                    <Typography sx={{ color: '#666' }}>→</Typography>
                    <Typography sx={{ 
                      color: '#fff',
                      fontSize: '0.875rem',
                    }}>
                      {mapping.repo_org}/{mapping.repo_name}
                    </Typography>
                    <Typography sx={{ 
                      color: '#666',
                      fontSize: '0.875rem',
                      ml: 1
                    }}>
                      ({mapping.development_branch || 'main'})
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={(e) => handleMenuOpen(e, mapping)}
                    sx={{ 
                      color: '#666',
                      p: 1,
                      '&:hover': {
                        color: '#fff',
                        backgroundColor: '#252525'
                      }
                    }}
                  >
                    <MoreVertical size={16} />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography sx={{ 
                color: '#666',
                fontSize: '0.875rem',
                textAlign: 'center',
                py: 2
              }}>
                No mappings found
              </Typography>
            )}
          </Box>

          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5,
              mb: 2
            }}>
              Link your GitHub repository to a studio:
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <FormControl fullWidth>
                <InputLabel 
                  id="studio-select-label"
                  sx={{ 
                    color: '#888',
                    '&.Mui-focused': {
                      color: '#888',
                    },
                  }}
                >
                  Select Studio
                </InputLabel>
                <Select
                  labelId="studio-select-label"
                  value={formData.studio_id}
                  onChange={handleStudioChange}
                  label="Select Studio"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2a2a',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2a2a',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2a2a',
                    },
                    color: '#fff',
                    '& .MuiSelect-icon': {
                      color: '#666',
                    },
                  }}
                >
                  {studioOptions.map((option) => (
                    <MenuItem 
                      key={option.value} 
                      value={option.value}
                      sx={{
                        backgroundColor: '#121212',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#252525',
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#252525',
                        },
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                color: '#666',
                height: '24px'
              }}>
                <KeyboardArrowDownIcon />
              </Box>

              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 2,
                backgroundColor: '#1a1a1a',
                borderRadius: 1,
                p: 2,
                border: '1px solid #2a2a2a'
              }}>
                <Typography sx={{ 
                  color: '#888',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  mb: 1
                }}>
                  Repository Details
                </Typography>
                
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    fullWidth
                    label="Organization"
                    value={formData.repo_org}
                    onChange={(e) => setFormData({ ...formData, repo_org: e.target.value })}
                    placeholder="organization"
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        '& fieldset': {
                          borderColor: '#2a2a2a',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#888',
                      },
                    }}
                  />
                  <Typography sx={{ 
                    color: '#666',
                    display: 'flex',
                    alignItems: 'center',
                    px: 1,
                    fontSize: '1.2rem'
                  }}>/</Typography>
                  <TextField
                    fullWidth
                    label="Repository"
                    value={formData.repo_name}
                    onChange={(e) => setFormData({ ...formData, repo_name: e.target.value })}
                    placeholder="repository"
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        '& fieldset': {
                          borderColor: '#2a2a2a',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#888',
                      },
                    }}
                  />
                </Box>

                <TextField
                  fullWidth
                  label="Development Branch (optional)"
                  value={formData.development_branch}
                  onChange={(e) => setFormData({ ...formData, development_branch: e.target.value })}
                  placeholder="default behavior is all branches"
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: '#fff',
                      '& fieldset': {
                        borderColor: '#2a2a2a',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#888',
                    },
                  }}
                />
              </Box>

              <Button
                onClick={handleLinkRepository}
                disabled={loading || !formData.studio_id || !formData.repo_name}
                sx={{
                  width: '100%',
                  height: '48px',
                  border: '1px solid #333',
                  backgroundColor: 'transparent',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#252525',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    color: '#666',
                    border: '1px solid #2a2a2a',
                  }
                }}
              >
                {loading ? 'Linking...' : 'Link Repository'}
              </Button>
            </Box>
          </Box>

        </Box>
      </DialogContent>

      <Box sx={{ 
        p: 3, 
        borderTop: '1px solid #2a2a2a',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#121212',
        position: 'sticky',
        bottom: 0,
        zIndex: 1
      }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#888',
            '&:hover': {
              backgroundColor: '#252525',
              color: '#fff'
            }
          }}
        >
          Cancel
        </Button>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            minWidth: 120,
          }
        }}
      >
        <MenuItem 
          onClick={handleDeleteMapping}
          disabled={deleteLoading}
          sx={{
            color: '#ff4d4f',
            fontSize: '0.875rem',
            '&:hover': {
              backgroundColor: '#252525'
            },
            gap: 1,
            display: 'flex',
            alignItems: 'center',
            '&.Mui-disabled': {
              color: '#ff4d4f',
              opacity: 0.5
            }
          }}
        >
          {deleteLoading ? (
            <CircularProgress size={16} sx={{ color: '#ff4d4f' }} />
          ) : (
            <Trash2 size={16} />
          )}
          Delete
        </MenuItem>
      </Menu>
    </Dialog>
  );
};

export default GithubModal; 