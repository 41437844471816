import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import logo from "./logo.svg";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Chat from "./pages/Chat";
import Home from "./pages/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import Settings from "./pages/Settings";
import { LastChatProvider } from "./contexts/LastChatContext";
import { Analytics } from "@vercel/analytics/react";
import { AuthProvider } from "./contexts/AuthContext";
import NotFound from "./pages/404";
import { ON_PREM } from "./globals";
import Studio from "./pages/Studio/Studio";
import { SidebarProvider } from './contexts/SidebarContext';
import { DataSourceProvider } from './contexts/DataSourceContext';
import DataSourcesPage from "./components/DataSourcesPage";
import Profile from "./pages/Profile";
import Integrations from "./pages/Integrations";
import PlaybooksPage from "./pages/PlaybooksPage";
import CommitChanges from "./pages/CommitChanges";
import Onboarding from "./pages/Onboarding";
import GithubCallback from "./components/GithubCallback";

function App() {
  useEffect(() => {
    document.title = "Fume | AI SWE";
    // Add the Hockeystack script if not ON_PREM
    if (!ON_PREM) {
      const hsscript = document.createElement("script");
      hsscript.src =
        "https://cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack.min.js";
      hsscript.async = true;
      hsscript.dataset.apikey = "4177b25dd8a4d4ebe8560537b1cb26";
      hsscript.dataset.cookieless = 1;
      hsscript.dataset.autoIdentify = 1;
      document.head.append(hsscript);
    }
  }, []);

  const getStudioComponent = () => {
    const studioId = window.location.pathname.split("/studio/")[1];
    return <Studio studioId={studioId} editMode={true} />;
  };

  return (
    <DataSourceProvider>
      <Router>
        <>
          <div>
            <AuthProvider>
              <LastChatProvider>
                <SidebarProvider>
                  <Routes>
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/onboarding/*" element={<Onboarding />} />
                    <Route path="/" element={<ProtectedRoute element={Home} />} />
                    <Route path="/github_callback" element={<ProtectedRoute element={GithubCallback} />} />
                    <Route
                      path="/chat/:task_id?"
                      element={
                          <ProtectedRoute
                            element={Chat}
                            key={window.location.pathname}
                          />
                      }
                    />
                    <Route
                      path="/settings"
                      element={<ProtectedRoute element={Settings} />}
                    />
                    <Route path="/notfound" element={<NotFound />} />
                    <Route path="/studio/:id?" element={<Studio editMode={true} />} />
                    <Route path="/studios" element={<ProtectedRoute element={DataSourcesPage} />} />
                    <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
                    <Route path="/integrations" element={<ProtectedRoute element={Integrations} />} />
                    <Route path="/playbooks" element={<ProtectedRoute element={PlaybooksPage} />} />
                    <Route path="/commit/:review_id" element={<CommitChanges />} />
                  </Routes>
                </SidebarProvider>
              </LastChatProvider>
            </AuthProvider>
          </div>
          {!ON_PREM && <Analytics />}
        </>
      </Router>
    </DataSourceProvider>
  );
}

export default App;
