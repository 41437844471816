import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import linearIcon from '../../assets/linear.png';
import axios from 'axios';
import { supabase } from '../../supabaseClient';
import { API_URL } from '../../globals';
import DataSourceSelector from '../DataSourceSelector';

const LinearModal = ({ open, onClose }) => {
  const [accessToken, setAccessToken] = useState('');
  const [linearURL, setLinearURL] = useState('');
  const [selectedLinearDataSource, setSelectedLinearDataSource] = useState('');
  const [showDataSourceSelector, setShowDataSourceSelector] = useState(false);
  const [studioOptions, setStudioOptions] = useState([]);
  const [isLoadingStudios, setIsLoadingStudios] = useState(false);
  const [selectedStudioName, setSelectedStudioName] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');

  useEffect(() => {
    fetchStudios();
  }, []);

  const fetchStudios = async () => {
    setIsLoadingStudios(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = { Authorization: `Bearer ${authToken}` };
      
      const studiosResponse = await axios.get(`${API_URL}/studio/seeds`, { headers });
      
      if (studiosResponse.data) {
        const studios = studiosResponse.data.filter(studio => studio.status === "READY" || studio.status === "BUSY");
        const options = studios.map((studio) => ({
          label: studio.display_name,
          value: studio.id,
          type: "studio",
          data: studio,
        }));
        setStudioOptions(options);
      }
    } catch (error) {
      console.error("Failed to fetch studios:", error);
    } finally {
      setIsLoadingStudios(false);
    }
  };

  const handleSubmit = async () => {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    try {
      await axios.post(`${API_URL}/linear/add-mapping`, {
        linear_url: `linear.app/${workspaceName}`,
        studio_id: selectedLinearDataSource,
      }, { headers });
      window.location.href = process.env.REACT_APP_LINEAR_OAUTH_URL;
    } catch (error) {
      console.error("Failed to add Linear mapping:", error);
    }
  };

  const handleDataSourceSelect = (dataSourceId) => {
    const selectedStudio = studioOptions.find(option => option.value === dataSourceId);
    setSelectedLinearDataSource(dataSourceId);
    setSelectedStudioName(selectedStudio?.label || '');
    setShowDataSourceSelector(false);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          borderRadius: 1,
          border: '1px solid #2a2a2a',
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 3,
        borderBottom: '1px solid #2a2a2a'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <img src={linearIcon} alt="Linear" width={24} height={24} />
          <Box>
            <Typography 
              sx={{ 
                color: '#fff',
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 0.5
              }}
            >
              Linear Integration
            </Typography>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem'
            }}>
              Connect your Linear workspace
            </Typography>
          </Box>
        </Box>
        <IconButton 
          onClick={onClose}
          sx={{ 
            color: '#666',
            '&:hover': {
              color: '#fff',
              backgroundColor: '#252525'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ 
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}>
          <Box sx={{
            backgroundColor: '#1a1a1a',
            border: '1px solid #2a2a2a',
            borderRadius: 1,
            p: 3,
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              lineHeight: 1.5
            }}>
              You can connect Fume to your Linear workspace. You can assign Fume tasks right from your Linear workspace. To integrate Fume with Linear, you'll need to install Fume Linear App on your workspace:
              <Box component="ul" sx={{ mt: 2, pl: 2 }}>
                <li>Enter your Linear URL (i.e. linear.app/your-workspace)</li>
                <li>Select the studio you want to direct Linear issues to</li>
                <li>Click "Connect" to install the app and authorize Fume</li>
              </Box>
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 2
          }}>
            <Typography sx={{ 
              color: '#888',
              fontSize: '0.875rem',
              mb: 1
            }}>
              Workspace URL
            </Typography>
            
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              backgroundColor: '#1a1a1a',
              border: '1px solid #2a2a2a',
              borderRadius: 1,
              px: 2,
              height: '40px',
            }}>
              <Typography sx={{ 
                color: '#666',
                fontSize: '0.875rem',
              }}>
                linear.app/
              </Typography>
              <TextField
                fullWidth
                placeholder="your-workspace"
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'transparent',
                    color: '#fff',
                    fontSize: '0.875rem',
                    '& fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    p: '8px 0',
                    height: '24px',
                    '&::placeholder': {
                      color: '#666',
                      opacity: 1,
                    },
                  },
                }}
              />
            </Box>
          </Box>

          <Button
            onClick={() => setShowDataSourceSelector(true)}
            sx={{
              backgroundColor: '#1a1a1a',
              border: '1px solid #2a2a2a',
              color: '#fff',
              textTransform: 'none',
              p: 2,
              justifyContent: 'space-between',
              '&:hover': {
                backgroundColor: '#252525',
                borderColor: '#404040',
              },
            }}
          >
            <Typography sx={{ color: selectedStudioName ? '#fff' : '#666' }}>
              {selectedStudioName || 'Select a Studio'}
            </Typography>
          </Button>

          <DataSourceSelector
            open={showDataSourceSelector}
            options={studioOptions}
            loading={isLoadingStudios}
            onSelect={handleDataSourceSelect}
            onClose={() => setShowDataSourceSelector(false)}
          />
        </Box>

        <Box sx={{ 
          p: 3, 
          borderTop: '1px solid #2a2a2a',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2
        }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#888',
              '&:hover': {
                backgroundColor: '#252525',
                color: '#fff'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!workspaceName || !selectedLinearDataSource}
            sx={{
              border: '1px solid #333',
              backgroundColor: 'transparent',
              color: '#fff',
              px: 3,
              '&:hover': {
                backgroundColor: '#252525',
              },
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: '#666',
                border: '1px solid #2a2a2a',
              }
            }}
          >
            CONNECT
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LinearModal; 