import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Container, CircularProgress, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GitHub } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { supabase } from "../supabaseClient";
import { API_URL } from "../globals";
import { Slack } from 'lucide-react';
// Custom icons for other providers
const GitLabIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z" />
  </svg>
);

const BitbucketIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M2.184 2.017a.432.432 0 0 0-.432.487l2.882 17.796a.584.584 0 0 0 .57.476h14.527a.432.432 0 0 0 .432-.378l2.882-17.894a.432.432 0 0 0-.432-.487H2.184zm13.293 11.977H8.51l-1.082-5.696h9.137l-1.088 5.696z" />
  </svg>
);

const AzureIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M21.007 8.222A3.738 3.738 0 0 0 15.045 5.2a5.583 5.583 0 0 0-10.92 1.516A4.456 4.456 0 0 0 4.5 15h15.757a3.738 3.738 0 0 0 .75-6.778zM12 2l3.423 4.159-6.847 5.801L12 2z" />
  </svg>
);

const integrationOptions = [
  {
    id: "github",
    name: "GitHub",
    icon: <GitHub sx={{ fontSize: 24 }} />,
  },
  {
    id: "gitlab",
    name: "GitLab",
    icon: <GitLabIcon />,
  },
  {
    id: "bitbucket",
    name: "Bitbucket",
    icon: <BitbucketIcon />,
  },
  {
    id: "azure",
    name: "Azure DevOps",
    icon: <AzureIcon />,
  },
];

const OnboardingProgress = ({ currentStep }) => {
  const steps = [
    { id: 1, label: "Sign Up" },
    { id: 2, label: "Connect" },
    { id: 3, label: "Team Size" },
    { id: 4, label: "Support" },
    { id: 5, label: "Create Studio" },
  ];

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 40,
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
        maxWidth: "440px",
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        {steps.map((step) => (
          <React.Fragment key={step.id}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: 2,
                  backgroundColor: step.id <= currentStep ? "#fff" : "#333",
                  transition: "background-color 0.3s ease",
                }}
              />
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: step.id <= currentStep ? "#888" : "#666",
                  transition: "color 0.3s ease",
                }}
              >
                {step.label}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

const TeamSizeStep = ({ onComplete, isLoading }) => {
  const [teamSize, setTeamSize] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    const size = parseInt(teamSize);
    if (isNaN(size) || size < 1) {
      setError(true);
      return;
    }
    onComplete(size);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setTeamSize(value);
      setError(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          textAlign: "center",
          mb: 6,
          maxWidth: "440px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 600,
            color: "#fff",
            mb: 2,
            letterSpacing: "-0.02em",
          }}
        >
          One Fit For All
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#888",
            lineHeight: 1.6,
            fontSize: "1.05rem",
          }}
        >
          How many engineers are in your team? You will not be charged until the
          end of your free trial.
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: "440px",
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            component="input"
            type="text"
            value={teamSize}
            onChange={handleChange}
            placeholder="Enter number of engineers"
            sx={{
              width: "100%",
              boxSizing: "border-box",
              p: 2.5,
              fontSize: "1rem",
              backgroundColor: "#1A1A1A",
              border: `1px solid ${error ? "#ff4444" : "#333"}`,
              borderRadius: 1,
              color: "#fff",
              outline: "none",
              transition: "all 0.2s ease",
              "&:hover": {
                borderColor: "#444",
              },
              "&:focus": {
                borderColor: "#555",
                backgroundColor: "#252525",
              },
            }}
          />

          {error && (
            <Typography
              sx={{
                color: "#ff4444",
                fontSize: "0.875rem",
                mt: 0.5,
              }}
            >
              Please enter a valid number
            </Typography>
          )}
        </Box>

        <Button
          fullWidth
          onClick={handleSubmit}
          disabled={isLoading}
          sx={{
            width: "100%",
            boxSizing: "border-box",
            justifyContent: "center",
            backgroundColor: "#1A1A1A",
            borderRadius: 1,
            p: 2.5,
            border: "1px solid #333",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#252525",
              borderColor: "#444",
            },
            transition: "all 0.2s ease",
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: "#fff" }} />
          ) : (
            "CONTINUE"
          )}
        </Button>
      </Box>
    </Box>
  );
};

const CreateStudioStep = ({ onComplete }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          textAlign: "center",
          mb: 6,
          maxWidth: "440px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 600,
            color: "#fff",
            mb: 2,
            letterSpacing: "-0.02em",
          }}
        >
          All done!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#888",
            lineHeight: 1.6,
            fontSize: "1.05rem",
          }}
        >
          Let's create your first studio. Studios are persistent development
          environments that Fume will use to work on your codebases.
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: "440px",
          mx: "auto",
        }}
      >
        <Button
          fullWidth
          onClick={onComplete}
          sx={{
            width: "100%",
            boxSizing: "border-box",
            justifyContent: "center",
            backgroundColor: "#1A1A1A",
            borderRadius: 1,
            p: 2.5,
            border: "1px solid #333",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#252525",
              borderColor: "#444",
            },
            transition: "all 0.2s ease",
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >
          CREATE STUDIO
        </Button>
      </Box>
    </Box>
  );
};

const EnterpriseOnlyStep = ({ provider, onBack }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          textAlign: "center",
          mb: 6,
          maxWidth: "440px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 600,
            color: "#fff",
            mb: 2,
            letterSpacing: "-0.02em",
          }}
        >
          {provider} Integration
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#888",
            lineHeight: 1.6,
            fontSize: "1.05rem",
          }}
        >
          {provider} integration is only available on our Enterprise plan.
          Contact our sales team to learn more about upgrading.
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: "440px",
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Button
          fullWidth
          onClick={() =>
            (window.location.href = "https://cal.com/fumedev/30min")
          }
          sx={{
            width: "100%",
            boxSizing: "border-box",
            justifyContent: "center",
            backgroundColor: "#1A1A1A",
            borderRadius: 1,
            p: 2.5,
            border: "1px solid #333",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#252525",
              borderColor: "#444",
            },
            transition: "all 0.2s ease",
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >
          CONTACT SALES
        </Button>

        <Button
          fullWidth
          onClick={onBack}
          sx={{
            width: "100%",
            boxSizing: "border-box",
            justifyContent: "center",
            backgroundColor: "transparent",
            borderRadius: 1,
            p: 2.5,
            color: "#888",
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.05)",
            },
            transition: "all 0.2s ease",
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

const formatChannelName = (companyName) => {
  // Remove special characters and whitespace, convert to lowercase
  return companyName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

const JoinSlackButton = ({ onComplete, email: initialEmail, channelName, isModular = false }) => {
  const [showEmailField, setShowEmailField] = useState(false);
  const [email, setEmail] = useState(initialEmail || '');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSendInvite = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const authToken = (await supabase.auth.getSession()).data.session.access_token;
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.post(
        `${API_URL}/slack_support/generate_invite`,
        {
          channel_name: channelName,
          email: email
        },
        { headers }
      );

      if (response.data.success) {
        const channelLink = response.data.channel_link;
        
        // First move to next step
        if (onComplete) {
          onComplete();
        }
        
        // Then open the channel link in a new tab after a small delay
        setTimeout(() => {
          const newWindow = window.open(channelLink, '_blank', 'noopener,noreferrer');
          if (!newWindow) {
            // Fallback: create a clickable link
            setError(
              <span>
                Popup blocked. Please{' '}
                <a href={channelLink} target="_blank" rel="noopener noreferrer" style={{ color: '#4A154B' }}>
                  click here
                </a>
                {' '}to join the Slack channel.
              </span>
            );
          }
        }, 100);
      } else {
        setError('Sorry, an error occurred');
      }
    } catch (error) {
      console.error('Error generating Slack invite:', error);
      setError('Sorry, an error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: isModular ? 'auto' : '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
      {!showEmailField ? (
        <Button
          onClick={() => setShowEmailField(true)}
          startIcon={<Slack size={20} color="#fff" />}
          sx={{
            backgroundColor: '#4A154B',
            color: '#fff',
            textTransform: 'none',
            px: 3,
            py: 1,
            '&:hover': {
              backgroundColor: '#611f69'
            }
          }}
        >
          Join Dedicated Channel
        </Button>
      ) : (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: isModular ? 'row' : 'column', gap: 2 }}>
          <TextField
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            type="email"
            disabled={isLoading}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                backgroundColor: '#1A1A1A',
                '& fieldset': {
                  borderColor: '#333',
                },
                '&:hover fieldset': {
                  borderColor: '#444',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4A154B',
                },
              },
              '& .MuiInputBase-input': {
                '&::placeholder': {
                  color: '#666',
                  opacity: 1,
                },
              },
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, minWidth: isModular ? '140px' : 'auto' }}>
            <Button
              onClick={handleSendInvite}
              disabled={isLoading}
              sx={{
                backgroundColor: '#4A154B',
                color: '#fff',
                textTransform: 'none',
                px: 3,
                py: 1,
                minWidth: '140px',
                whiteSpace: 'nowrap',
                '&:hover': {
                  backgroundColor: '#611f69'
                },
                '&.Mui-disabled': {
                  backgroundColor: '#2A0F2B',
                  color: '#888'
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {isLoading ? (
                  <CircularProgress size={20} sx={{ color: '#fff' }} />
                ) : (
                  <Slack size={20} color="#fff" />
                )}
                {isLoading ? 'Sending...' : 'Send Invite'}
              </Box>
            </Button>
            {error && (
              <Typography 
                sx={{ 
                  color: '#f44336',
                  fontSize: '0.75rem',
                  textAlign: 'center'
                }}
              >
                {error}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const JoinSlackChannelStep = ({ onComplete, userData }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          textAlign: "center",
          mb: 6,
          maxWidth: "440px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 600,
            color: "#fff",
            mb: 2,
            letterSpacing: "-0.02em",
          }}
        >
          Dedicated Support Channel
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#888",
            lineHeight: 1.6,
            fontSize: "1.05rem",
            mb: 4,
          }}
        >
          Join a private Slack channel with our engineering team for direct support. This is not a community channel.
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center' }}>
          <JoinSlackButton 
            onComplete={onComplete}
            email={userData?.email}
            channelName={formatChannelName(userData?.companyName || '')}
          />
          
          <Button
            onClick={onComplete}
            sx={{
              color: "#888",
              textTransform: "none",
              fontSize: "0.9rem",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.05)",
              },
            }}
          >
            Skip for now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function Onboarding() {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState("provider");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [isGithubLoading, setIsGithubLoading] = useState(false);
  const [isTeamSizeLoading, setIsTeamSizeLoading] = useState(false);
  const userData = location.state || JSON.parse(localStorage.getItem('onboardingUserData') || '{}');

  useEffect(() => {
    if (location.pathname === "/onboarding/github_callback") {
      setStep("team_size");
    } else if (location.pathname === "/onboarding/join_slack") {
      setStep("join_support_channel");
    }
  }, [location]);

  useEffect(() => {
    // Set onboarding flag when component mounts
    localStorage.setItem('inOnboarding', 'true');
  }, []);

  const installGitHubApp = async () => {
    setIsGithubLoading(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session
        .access_token;

      if (!authToken) {
        throw new Error("No authentication token found");
      }

      const response = await axios.get(`${API_URL}/github-install`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        maxRedirects: 5,
        validateStatus: function (status) {
          return status >= 200 && status < 400;
        },
      });

      if (response.data?.url) {
        window.location.href = response.data.url;
      } else {
        window.location.href = response.request.responseURL;
      }
    } catch (error) {
      console.error("Error starting GitHub installation:", error);
      setIsGithubLoading(false);
    }
  };

  const handleIntegrationSelect = (integrationId) => {
    if (integrationId === "github") {
      installGitHubApp();
    } else {
      setSelectedProvider(integrationId);
      setStep("enterprise_only");
    }
  };

  const handleTeamSizeSubmit = async (teamSize) => {
    setIsTeamSizeLoading(true);
    try {
      const authToken = (await supabase.auth.getSession()).data.session
        .access_token;

      const redirectUrl = encodeURIComponent(
        `${window.location.origin}/onboarding/join_slack`
      );

      const response = await axios.get(
        `${API_URL}/stripe/generate_checkout_url`,
        {
          params: {
            default_quantity: teamSize,
            redirect: redirectUrl,
          },
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      if (response.data.url) {
        window.location.href = response.data.url;
        return;
      }

      setStep("join_support_channel");
    } catch (error) {
      console.error("Error during checkout process:", error);
      localStorage.setItem('inOnboarding', 'false');
      setIsTeamSizeLoading(false);
    }
  };

  const handleJoinCommunityComplete = () => {
    setStep("create_studio");
  };

  const handleCreateStudio = () => {
    // Remove onboarding flags and user data when onboarding is complete
    localStorage.setItem('inOnboarding', 'false');
    localStorage.removeItem('onboardingUserData');
    navigate('/studios?openModal=true');
  };

  // Show different content based on the current step
  if (step === "create_studio") {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "#121212",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          position: "relative",
        }}
      >
        <OnboardingProgress currentStep={5} />
        <Container maxWidth="sm">
          <Box
            sx={{
              p: { xs: 2, sm: 4 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CreateStudioStep onComplete={handleCreateStudio} />
          </Box>
        </Container>
      </Box>
    );
  }

  if (step === "join_support_channel") {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "#121212",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          position: "relative",
        }}
      >
        <OnboardingProgress currentStep={4} />
        <Container maxWidth="sm">
          <Box
            sx={{
              p: { xs: 2, sm: 4 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <JoinSlackChannelStep 
              onComplete={handleJoinCommunityComplete} 
              userData={userData}
            />
          </Box>
        </Container>
      </Box>
    );
  }

  if (step === "team_size") {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "#121212",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          position: "relative",
        }}
      >
        <OnboardingProgress currentStep={3} />
        <Container maxWidth="sm">
          <Box
            sx={{
              p: { xs: 2, sm: 4 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TeamSizeStep onComplete={handleTeamSizeSubmit} isLoading={isTeamSizeLoading} />
          </Box>
        </Container>
      </Box>
    );
  }

  if (step === "enterprise_only") {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "#121212",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          position: "relative",
        }}
      >
        <OnboardingProgress currentStep={2} />
        <Container maxWidth="sm">
          <Box
            sx={{
              p: { xs: 2, sm: 4 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <EnterpriseOnlyStep
              provider={
                integrationOptions.find((opt) => opt.id === selectedProvider)
                  ?.name
              }
              onBack={() => setStep("provider")}
            />
          </Box>
        </Container>
      </Box>
    );
  }

  // Original provider selection view
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#121212",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        position: "relative",
      }}
    >
      <OnboardingProgress currentStep={2} />
      <Container maxWidth="sm">
        <Box
          sx={{
            p: { xs: 2, sm: 4 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              mb: 6,
              maxWidth: "440px",
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: "#fff",
                mb: 2,
                letterSpacing: "-0.02em",
              }}
            >
              {userData?.fullName ? 
                `Welcome to Fume, ${userData.fullName.split(' ')[0]}!` : 
                'Welcome to Fume!'}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#888",
                lineHeight: 1.6,
                fontSize: "1.05rem",
              }}
            >
              {userData?.companyName ? 
                `Let's get started by connecting ${userData.companyName}'s code repository. Install our app for Fume to be triggered on every pull request.` :
                "Let's get started by connecting your code repository. Install our app for Fume to be triggered on every pull request."
              }
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              width: "100%",
            }}
          >
            {integrationOptions.map((option) => (
              <Button
                key={option.id}
                fullWidth
                disabled={option.id === "github" && isGithubLoading}
                sx={{
                  justifyContent: "flex-start",
                  backgroundColor: "#1A1A1A",
                  borderRadius: 1,
                  p: 2.5,
                  "&:hover": {
                    backgroundColor: "#252525",
                  },
                  transition: "all 0.2s ease",
                  border: "1px solid #333",
                }}
                onClick={() => handleIntegrationSelect(option.id)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      color: "#fff",
                      mr: 3,
                      display: "flex",
                      alignItems: "center",
                      opacity: 0.9,
                    }}
                  >
                    {option.id === "github" && isGithubLoading ? (
                      <CircularProgress size={24} sx={{ color: "#fff" }} />
                    ) : (
                      option.icon
                    )}
                  </Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      flex: 1,
                      textAlign: "left",
                      fontSize: "1rem",
                      letterSpacing: "-0.01em",
                    }}
                  >
                    {option.name}
                  </Typography>
                </Box>
              </Button>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
