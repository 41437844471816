import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { Github, Gitlab, FileCode, kanban, Kanban, Slack } from 'lucide-react';
import NavBar from "../components/NavBar";
import IntegrationCard from "../components/IntegrationCard";
import GithubModal from "../components/IntegrationModals/GithubModal";
import GitlabModal from "../components/IntegrationModals/GitlabModal";
import AzureDevopsModal from "../components/IntegrationModals/AzureDevopsModal";
import JiraModal from "../components/IntegrationModals/JiraModal";
import SlackModal from "../components/IntegrationModals/SlackModal";
import LinearModal from "../components/IntegrationModals/LinearModal";
import BitbucketModal from "../components/IntegrationModals/BitbucketModal";
import azureIcon from '../assets/azure-devops.png';
import jiraIcon from '../assets/jira.png';
import linearIcon from '../assets/linear.png';
import bitbucketIcon from '../assets/bitbucket.png';
import { supabase } from "../supabaseClient";
import axios from "axios";
import { API_URL } from "../globals";

const Integrations = () => {
  const [activeModal, setActiveModal] = useState(null);
  const [user, setUser] = useState(null);
  const [isEnterpriseUser, setIsEnterpriseUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function checkEnterpriseStatus() {
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/is_enterprise_user`, {
        headers: headers,
      });

      if (response.data) {
        setIsEnterpriseUser(response.data.is_enterprise_user);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    checkEnterpriseStatus();
  }, []);

  const integrations = [
    {
      id: 'github',
      name: 'GitHub',
      description: 'Add Fume to your GitHub organization',
      icon: <Github size={24} />,
      color: '#ffff'
    },
    {
      id: 'gitlab',
      name: 'GitLab',
      description: 'Add Fume to your GitLab organization',
      icon: <Gitlab size={24} />,
      color: '#ffff',
      isEnterprise: false
    },
    {
        id: 'slack',
        name: 'Slack',
        description: 'Fume will ping you in Slack when it needs your help',
        icon: <Slack size={24} />,
        color: '#ffff'
    },
    {
      id: 'linear',
      name: 'Linear',
        description: 'Assign Linear issues to Fume',
        icon: <img src={linearIcon} alt="Linear" width={24} height={24} />,
        color: '#ffff',
        isEnterprise: false
    },
    /*{
      id: 'azure',
      name: 'Azure DevOps',
      description: 'Add Fume to your Azure DevOps organization',
      icon: <img src={azureIcon} alt="Azure DevOps" width={24} height={24} />,
      color: '#ffff',
      isEnterprise: true
    },
    {
      id: 'jira',
      name: 'Jira',
      description: 'Assign Jira issues to Fume',
      icon: <img src={jiraIcon} alt="Jira" width={24} height={24} />,
      color: '#ffff',
      isEnterprise: true
    },*/
    { 
      id: 'bitbucket',
      name: 'Bitbucket',
      description: 'Add Fume to your Bitbucket organization',
      icon: <img src={bitbucketIcon} alt="Bitbucket" width={24} height={24} />,
      color: '#ffff',
      isEnterprise: true
    }
  ];

  const handleOpenModal = (integrationId) => {
    setActiveModal(integrationId);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  return (
    <>
      <NavBar user={user} />
      <Box sx={{ 
        minHeight: '100vh',
        pt: 8,
        px: 4,
        backgroundColor: '#121212',
      }}>
        <Box sx={{
          maxWidth: '1200px',
          margin: '0 auto',
        }}>
          {/* Header */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 6
          }}>
            <Box>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 600, 
                  color: '#fff',
                  mb: 1
                }}
              >
                Integrations
              </Typography>
              <Typography sx={{ color: '#888', fontSize: '0.875rem' }}>
                Connect Fume to your development tools and services
              </Typography>
            </Box>
          </Box>

          {/* Integration Cards Grid */}
          <Grid container spacing={3}>
            {integrations.map((integration) => (
              <Grid item xs={12} sm={6} md={4} key={integration.id}>
                <IntegrationCard
                  integration={integration}
                  onClick={() => handleOpenModal(integration.id)}
                  hasEnterpriseTier={isEnterpriseUser}
                  isLoading={isLoading}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Integration Modals */}
        <GithubModal 
          open={activeModal === 'github'} 
          onClose={handleCloseModal} 
        />
        <GitlabModal 
          open={activeModal === 'gitlab'} 
          onClose={handleCloseModal} 
        />
        <AzureDevopsModal 
          open={activeModal === 'azure'} 
          onClose={handleCloseModal} 
        />
        <JiraModal 
          open={activeModal === 'jira'} 
          onClose={handleCloseModal} 
        />
        <BitbucketModal 
          open={activeModal === 'bitbucket'} 
          onClose={handleCloseModal} 
        />
      </Box>
      <SlackModal 
        open={activeModal === 'slack'} 
        onClose={handleCloseModal} 
      />
      <LinearModal 
        open={activeModal === 'linear'} 
        onClose={handleCloseModal} 
      />
    </>
  );
};

export default Integrations; 